// import "@babel/polyfill";
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, withStyles, Box, Typography } from '@material-ui/core';

import { CloseRounded, MenuRounded } from '@material-ui/icons';
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import MarketIcon from '../../icons/material_icons/MarketIcon';
import PortfolioIcon from '../../icons/material_icons/PortfolioIcon';

import RsuiteTable from './RsuiteTable';
import RightPanel from './right_panel/RightPanel';
import Header from './Header';
import LoadingComponent from './LoadingComponent';
import {
  actionPlus,
  actionMinus,
  actionFirstHeaderIcon,
  actionLastHeaderIcon,
  actionLoadData,
  actionSelectTableView,
  actionSelectChartView,
  actionChangeSelectedDate,
  actionChangeChartControl,
  actionSelectView,
  actionUpdateConfig,
  actionCloseConfig,
  actionSaveConfig,
  actionSetFsIndex,
  actionSetFsTable,
} from './../../store/actions';
import { actionShowModal, actionHideModal } from './../Modals/actions';

import './Main.scss';

import ChartControl from './ChartControl';
import Charts from './Charts';

const rightButtons = [
  {
    name: 'Market',
    view: 'market',
    icon: <MarketIcon />,
  },
  {
    name: 'Country',
    view: 'country',
    icon: <PublicOutlinedIcon />,
  },
  {
    name: 'Portfolio',
    view: 'portfolio',
    icon: <PortfolioIcon />,
  },
  {
    name: 'Executive',
    view: 'executive',
    icon: <OfflineBoltOutlinedIcon />,
  },
];

const leftButtonsTable = [
  {
    name: 'Trend',
  },
  {
    name: 'Customized',
  },
];

const leftButtonsCharts = [
  {
    index: 0,
    name: 'market',
    title: 'Market',
  },
  {
    index: 1,
    name: 'finance',
    title: 'Finance',
  },
  {
    index: 2,
    name: 'epi',
    title: 'Epi',
  },
  {
    index: 3,
    name: 'weekly',
    title: 'Weekly',
  },
  {
    index: 4,
    name: 'promo',
    title: 'Promo',
  },
  {
    index: 5,
    name: 'forecast',
    title: 'Forecast',
  },
  {
    index: 6,
    name: 'patient',
    title: 'Patients',
  },
  {
    index: 7,
    name: 'atu',
    title: 'ATU',
  },
  {
    index: 8,
    name: 'finance_account',
    title: 'Account',
  },
  {
    index: 9,
    name: 'siso',
    title: 'SISO',
  },
  {
    index: 10,
    name: 'market',
    title: 'EOG',
  },
  {
    index: 11,
    name: 'patient_weekly',
    title: 'Patients Weekly',
    className: 'large-tab',
  },
  {
    index: 12,
    name: 'sit',
    title: 'SIT',
  },
  {
    index: 13,
    name: 'sfe',
    title: 'SFE',
  },
  {
    index: 14,
    name: 'daily_sales',
    title: 'Daily (beta)',
  },
  {
    index: 15,
    name: 'insights',
    title: 'Insights (beta)',
    className: 'large-tab',
  },
  {
    index: 16,
    name: 'sob',
    title: 'SOB',
  },
  {
    index: 17,
    name: 'peq',
    title: 'PEQ',
  },
  {
    index: 18,
    name: 'ma',
    title: 'Market Access',
    className: 'large-tab',
  },
  {
    index: 19,
    name: 'crm',
    title: 'CRM',
  },
];
// <country yid="C_AUT"/>

const WideBox = withStyles((theme) => ({
  root: {
    flexBasis: '100%',
    width: '100%',
  },
}))(Box);

const useStyles = makeStyles((theme) => ({
  showRP: {
    position: 'fixed',
    top: 65,
    right: 5,
    zIndex: 11,
    cursor: 'pointer',
  },
  withRP: {
    paddingRight: '0!important',
    [theme.breakpoints.up('md')]: {
      paddingRight: '250px!important',
    },
  }
}));

const Main = (props) => {
  const classes = useStyles();

  window.keys = {};

  const [state, setState] = useState({
    isInsightsLoading: false,
    insightsData: '',
  });

  const {
    config_id,
    loading,
    view,
    controlsData,
    channels,
    chartControls,
    datasource,
    actives,
    isShowRP,
    activeTableView,
    activeChartView,
    dateParse,
    data,
    selectedTabId,
    actionChangeChartControl,
    aggrData,
    actionSetFsTable,
    actionSetFsIndex,
    fs_table,
    fs_index,
  } = props;

  const keyDownHandler = (e) => {
    window.keys.ctrlKey = e.ctrlKey;
    window.keys.metaKey = e.metaKey;
    window.keys.shiftKey = e.shiftKey;
    if (e.keyCode === 27) {
      actionSetFsTable(false);
      actionSetFsIndex(null);
    }
  };
  const keyUpHandler = (e) => {
    window.keys.ctrlKey = e.ctrlKey;
    window.keys.metaKey = e.metaKey;
    window.keys.shiftKey = e.shiftKey;
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler, false);
    document.addEventListener('keyup', keyUpHandler, false);
    return () => {
      document.removeEventListener('keydown', keyDownHandler, false);
      document.removeEventListener('keyup', keyUpHandler, false);
    };
  });

  /*componentDidMount(){
    document.addEventListener("keydown", keyDownHandler, false);
    document.addEventListener("keyup", keyUpHandler, false);
  }
  componentWillUnmount(){
    
    document.removeEventListener("keydown", keyDownHandler, false);
    document.removeEventListener("keyup", keyUpHandler, false);
  }*/

  if (loading) {
    return loading.type === 'AccessIsDenied' ? (
      <Box mt="25%">
        <Typography variant="h5" component="h4" align="center">
          Access is denied.
        </Typography>
        <Box mt={3}>
          <Typography variant="body2" component="p" align="center">
            Please request access to{' '}
            <a href="mailto:flashcard@sanofi.com">flashcard@sanofi.com</a>
          </Typography>
        </Box>
      </Box>
    ) : (
      <LoadingComponent />
    );
  }

  return (
    <div className="main">
      {fs_index !== null || fs_table ? (
        <div className="overlay-fs" style={{ zIndex: fs_table ? 2 : 9 }}></div>
      ) : null}

      {fs_index === null && !fs_table ? (
        <Header {...props} />
      ) : (
        <Box
          display="flex"
          justifyContent="flex-end"
          className={classes.showRP}>
          <Box onClick={() => props.actionLastHeaderIcon(props.isShowRP)}>
            {isShowRP ? <CloseRounded /> : <MenuRounded />}
          </Box>
        </Box>
      )}

      <Box
        display="flex"
        className={`m-main ${
          isShowRP ? 'with-right-panel ' + classes.withRP : ''
        } ${fs_index !== null || fs_table ? 'fs-right-panel' : ''}`}>
        {/* <div className={"col activeRPanel " + (isShowRP ? "col-10" : "col-12")}> */}
        <WideBox mx={2} className={'activeRPanel'}>
          <WideBox className="table-container" mb={1}>
            <div className="table-tabs">
              <div>
                {leftButtonsTable.map((m, i) => (
                  <div
                    key={i}
                    className={`table-tabs-buttons ${
                      activeTableView === i ? 'selected' : ''
                    }`}
                    onClick={() => props.actionSelectTableView(i)}>
                    <div className="button">{m.name}</div>
                  </div>
                ))}
              </div>

              <div>
                {rightButtons
                  .filter((m) => !m.hidden)
                  .map((m, i) => (
                    <div
                      key={i}
                      className={`table-tabs-buttons ${
                        view === m.view ? 'selected' : ''
                      }`}
                      onClick={() => props.actionSelectView(m.view)}>
                      <div className="button">
                        {/*<img src={m.icon} alt="" />*/}
                        {m.icon}
                        {m.name}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <RsuiteTable
              {...props}
              full_screen={fs_table}
              handlerFS={() => actionSetFsTable(!fs_table)}
            />
          </WideBox>

          <WideBox display="flex" className="charts-container">
            <div className="chart-tabs">
              {leftButtonsCharts
                .filter(
                  (m) =>
                    !m.hidden &&
                    !(
                      view === 'executive' &&
                      ![
                        'market',
                        'finance',
                        'weekly',
                        'sit',
                        'sfe',
                        'crm',
                        'daily_sales',
                        'insights',
                      ].includes(m.name)
                    )
                )
                .map((m) => (
                  <div
                    key={m.index}
                    className={`chart-tabs-buttons ${
                      activeChartView === m.index ? 'selected' : ''
                    } ${m.className ? m.className : ''}`}
                    onClick={() => props.actionSelectChartView(m.index)}>
                    <div className="button">{m.title}</div>
                  </div>
                ))}
            </div>
            <Box mb={0.5} display="flex" justifyContent="flex-end">
              <ChartControl
                controlsData={controlsData}
                chartControls={chartControls}
                actionChangeChartControl={actionChangeChartControl}
                actives={actives}
                channels={channels}
                data={data}
                controlName={leftButtonsCharts[activeChartView].name}
                aggrData={aggrData}
              />
            </Box>

            <Charts
              params={props}
              state={state}
              setState={setState}
              leftButtonsCharts={leftButtonsCharts}
            />
          </WideBox>
        </WideBox>

        <WideBox className="right-panel-container">
          {/* <div style={{position: "absolute", top: "0px", left: "-34px", cursor: "pointer", backgroundColor: "#f9f8f9", width: "40px", height: "25px"}} onClick={() => props.actionLastHeaderIcon(isShowRP)}>
              <span uk-icon="icon: settings; ratio: 1"></span> 
            </div> */}
          <RightPanel
            config_id={config_id}
            controlsData={controlsData}
            selectedTabId={selectedTabId}
            datasource={datasource}
            dateParse={dateParse}
          />
        </WideBox>
      </Box>
    </div>
  );
};

const mapStateToProps = (rootState, props) => {
  const { config_id } = props;
  const state = rootState.data.configs[config_id];
  const project_config = rootState.data.project_config;

  if (state.datasource) {
    const { subsetMap, daily, yseop, market_access, titles } = state.datasource;
    leftButtonsCharts.forEach((d) => {
      d.hidden = !(subsetMap[d.name] && subsetMap[d.name].dataLength > 0);
      // EOG
      if (d.index === 10 && !subsetMap[d.name].columns.includes('PE12PUBPCK'))
        d.hidden = true;
      // Daily Sales
      if (d.index === 14 && daily && state.daily_data) d.hidden = false;
      // Insights
      if (
        d.index === 15 &&
        yseop &&
        ['market', 'executive'].includes(state.view)
      )
        d.hidden = false;
      // Market Access
      if (d.index === 18 && market_access && state.market_access)
        d.hidden = false;
    });

    // if reference market == "" - disable switching to executive view
    rightButtons.find((d) => d.view === 'executive').hidden =
      titles && titles['Reference Market'] === '';
  }

  return {
    ...state,
    config_id,
    loading: state.loading || !state.datasource || !state.aggrData,
    query_loading: rootState.data.query_loading || 0,
    isOpenModal: rootState.modal.open,
    charts: state.charts || [],
    color_codes: state.color_codes || {},
    tab_footnote: state.tab_footnote || {},
    chartControls: state.chartControls || {},
    activeChartView: state.activeChartView || 0,
    activeTableView: state.activeTableView,
    controlsData: state.controlsData,
    datasources: rootState.data.datasources,
    actives: state.actives,
    isTooltip: state.isTooltip,
    isShowRP: state.isShowRP,
    market_access: state.market_access,
    metrics:
      (state.controlsData &&
        state.controlsData.length &&
        state.controlsData[3].data) ||
      [],
    links: project_config.links,
    questionLinks: project_config.questionLinks,
    fs_index: rootState.data.fs_index,
    fs_table: rootState.data.fs_table,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { config_id, selectedTab } = props;
  console.log('****', props);
  return {
    actionPlus: (id) => dispatch(actionPlus(config_id, id)),
    actionMinus: (id) => dispatch(actionMinus(config_id, id)),
    actionFirstHeaderIcon: (bool) =>
      dispatch(actionFirstHeaderIcon(config_id, bool)),
    actionLastHeaderIcon: (bool) =>
      dispatch(actionLastHeaderIcon(config_id, bool)),
    actionLoadData: (activeDataset, userConfig) =>
      dispatch(actionLoadData(activeDataset, userConfig)),
    actionSelectTableView: (index) =>
      dispatch(actionSelectTableView(config_id, index)),
    actionSelectChartView: (index) =>
      dispatch(actionSelectChartView(config_id, index)),
    actionChangeSelectedDate: (id, date) =>
      dispatch(actionChangeSelectedDate(config_id, id, date)),
    actionChangeChartControl: (name, type, value) =>
      dispatch(actionChangeChartControl(config_id, name, type, value)),
    actionSelectView: (data, key, dim) =>
      dispatch(actionSelectView(config_id, data, key, dim)),
    actionUpdateConfig: (data) =>
      dispatch(actionUpdateConfig({ config_id, data })),
    actionCloseConfig: (action) =>
      dispatch(
        actionCloseConfig({ action, config_id, activeTab: selectedTab })
      ),
    actionSaveConfig: () => dispatch(actionSaveConfig(config_id)),
    actionShowModal: (modalType, config) =>
      dispatch(actionShowModal(modalType, { config_id, config })),
    actionHideModal: () => dispatch(actionHideModal()),
    actionSetFsTable: (value) => dispatch(actionSetFsTable(value)),
    actionSetFsIndex: (value) => dispatch(actionSetFsIndex(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
