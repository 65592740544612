import update from 'immutability-helper';

import * as actions from '../../actions';

import {
  getAggregationData,
  getTableData,
  getChartData,
  calculateLastDate,
  calculateActives,
  updateHighlights,
  getSortTree,
  updateDimensions,
  findSelectedId,
  getKPIs,
  getCustomKPIs,
  getCharts,
  getChartControls,
} from './helper';

import { initState, postQueryUpdate } from './initialize';

const dimensions = [
  { name: 'Region', index: 0 },
  { name: 'Zone', index: 1 },
  { name: 'Country', index: 2 },
  { name: 'Cluster', index: 3 },
  { name: 'Channel Group', index: 4 },

  { name: 'Category5', index: 5 },
  { name: 'Category4', index: 6 },
  { name: 'Category3', index: 7 },
  { name: 'Category2', index: 8 },
  { name: 'Category1', index: 9 },
  { name: 'Category0', index: 10 },
  { name: 'Segment', index: 11 },

  { name: 'Corporation Group 2', index: 12 },
  { name: 'Corporation Group 1', index: 13 },
  { name: 'Product Group 3', index: 14 },
  { name: 'Product Group 2', index: 15 },
  { name: 'Product Group 1', index: 16 },
  { name: 'PI Status', index: 17 },
];

const init_state = {
  loading: false,
};

const filterReferenceMarketExec = (d, keyProduct, market) => {
  const arr = d.split(':'); // arr[0] - Product Portfolio, arr.slice(1) - Reference markets
  return (
    (arr[0] === keyProduct ||
      arr[0] === null ||
      arr[0] === 'null' ||
      arr[0] === '') &&
    arr.slice(1).includes(market)
  );
};
const filterReferenceMarket = (d, key) => {
  const arr = d.split(':'); // arr[0] - Product Portfolio, arr.slice(1) - Reference markets
  return arr
    .slice(1)
    .map((s) => arr[0] + ':' + s)
    .includes(key);
};

export default (rootState, { type, payload, config_id }) => {
  const state = rootState.configs[config_id];
  const { project_config } = rootState;

  switch (type) {
    // CONFIG
    case actions.CONFIG_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }

    case actions.DID_SELECT_CONFIG: {
      const { project_config: config } = rootState;
      const { config: defaultConfig } = state;

      // TODO: need to use correct user data
      const user = {};

      const datasource = {
        ...payload.config,
        ...payload.data,
        datasource_id: payload.datasource_id,
        query_cache: payload.query_cache,
      };

      // kpis for customized table
      if (config.userTypes) {
        datasource.kpiGroups = config.kpiGroups.filter((d) => {
          return (
            (datasource.subsetMap[d.dataType] &&
              datasource.subsetMap[d.dataType].dataLength > 0) ||
            (d.dataType === 'target' && defaultConfig.target_data)
          );
        });
        const groups = datasource.kpiGroups.map((d) => d.name);
        // check first if user save custom kpis in User Default Config, if not => take it from common Config
        datasource.kpis =
          defaultConfig.customKPIs && defaultConfig.customKPIs.length
            ? defaultConfig.customKPIs.filter((d) => groups.includes(d.group))
            : (
                config.userTypes.find(
                  (d) => d.name === (user.User_Type || 'User Type 0')
                ) || { kpis: [] }
              ).kpis.filter((d) => groups.includes(d.group));

        if (datasource.kpiGroups && datasource.kpis) {
          datasource.kpis.forEach((d, i) => {
            d.order = i;
            d.id =
              datasource.kpiGroups.find((g) => g.name === d.group).order +
              ':' +
              d.order;
          });
        }
      }

      return {
        ...state,
        datasource,
        default_user_config: payload.default_user_config,
      };
    }

    case actions.DID_SAVE_CONFIG: {
      return {
        ...state,
        config: {
          ...state.config,
          ...payload.newData,
        },
      };
    }

    case actions.INIT_CONFIG: {
      let newState = initState(state, project_config);
      return {
        ...state,
        ...newState,
      };
    }

    case actions.DID_INIT_CONFIG: {
      const newState = {
        ...state,
      };

      // recalculate last date
      newState.dateEnd = calculateLastDate(newState);

      newState.tableData = getTableData(newState);
      newState.selectedId = findSelectedId(newState); //state.tableData.data[0].id;

      newState.chartControls = getChartControls(newState);
      newState.chartData = getChartData(newState);
      return newState;
    }

    // QUERY

    case actions.DID_QUERY: {
      return {
        ...state,
        aggrData: payload,
      };
    }

    // OTHER
    case actions.SELECT: {
      const newState = {
        ...state,
        selectedId: payload.id,
      };
      newState.chartData = getChartData(newState);

      return newState;
    }

    case actions.SELECT_CONTROL_TAB: {
      return {
        ...state,
        selectedTabId: payload,
      };
    }

    case actions.SELECT_TABLE_VIEW: {
      const newState = {
        ...state,
        activeTableView: payload,
      };

      newState.defaultKPIs = update(newState.defaultKPIs, {
        0: {
          kpis: {
            $set: getKPIs(newState, newState.controlsData),
          },
        },
        1: {
          kpis: {
            $set: getCustomKPIs(newState, newState.controlsData),
          },
        },
      });

      newState.charts = getCharts(newState);

      return newState;
    }
    case actions.DID_SELECT_TABLE_VIEW: {
      const newState = { ...state };

      newState.tableData = getTableData(newState);
      newState.selectedId = findSelectedId(newState);

      newState.chartControls = getChartControls(newState);
      newState.chartData = getChartData(newState);

      return newState;
    }

    case actions.SELECT_CHART_VIEW: {
      return {
        ...state,
        activeChartView: payload,
      };
    }

    case actions.CHANGE_SORT: {
      return {
        ...state,
        sortId: payload.sortId,
        sortOrder: payload.sortOrder,
        tableData: getSortTree(
          state.tableData,
          payload.sortId,
          payload.sortOrder
        ),
      };
    }

    case actions.CHANGE_KPI: {
      const newState = {
        ...state,
      };

      if (payload.type === 'Edit') {
        const kpi = newState.datasource.kpis.find(
          (d) => d.id === payload.data.id
        );
        kpi.metric = payload.data.metric;
        kpi.name = payload.data.kpi;
        kpi.aggr = payload.data.aggr;
        kpi.customer_segment = payload.data.customer_segment;
        kpi.customer_specialty = payload.data.customer_specialty;
      } else if (payload.type === 'Add') {
        const kpi = {
          title: payload.data.group,
          dataType: payload.data.dataType,
          name: payload.data.kpi,
          metric: payload.data.metric,
          aggr: payload.data.aggr,
          group: payload.data.group,
          customer_segment: payload.data.customer_segment,
          customer_specialty: payload.data.customer_specialty,
        };

        newState.datasource.kpis.push(kpi);

        newState.datasource.kpis.forEach((d, i) => {
          d.order = i;
          d.id =
            newState.datasource.kpiGroups.find((g) => g.name === d.group)
              .order +
            ':' +
            d.order;
        });
      } else if (payload.type === 'Delete') {
        const idx = newState.datasource.kpis
          .map((d) => d.id)
          .indexOf(payload.data.id);
        newState.datasource.kpis.splice(idx, 1);

        newState.datasource.kpis.forEach((d, i) => {
          d.order = i;
          d.id =
            newState.datasource.kpiGroups.find((g) => g.name === d.group)
              .order +
            ':' +
            d.order;
        });
      }

      newState.defaultKPIs = update(newState.defaultKPIs, {
        0: {
          kpis: {
            $set: getKPIs(newState, newState.controlsData),
          },
        },
        1: {
          kpis: {
            $set: getCustomKPIs(newState, newState.controlsData),
          },
        },
      });

      newState.charts = getCharts(newState);

      return newState;
    }
    case actions.DID_CHANGE_KPI: {
      const newState = { ...state };

      newState.tableData = getTableData(newState);
      newState.selectedId = findSelectedId(newState);

      newState.chartControls = getChartControls(newState);
      newState.chartData = getChartData(newState);

      return newState;
    }

    case actions.SELECT_VIEW: {
      const newState = {
        ...state,
        view: payload.data,
      };

      const isCHC = ['CHC.json', 'CHC2.json'].includes(
        newState.datasource.filename
      );
      const SANOFI_CORP = isCHC ? 'SANOFI CHC' : 'SANOFI';

      let newControlsData = state.controlsData;

      if (state.view === 'executive' && payload.key) {
        // go FROM executive view to other view
        let market, country, category5, keyProduct;
        if (state.dims.includes('Category5')) {
          [market, country, category5, keyProduct] = payload.key
            .replace(' in ', ':')
            .split(':');
        } else {
          [market, country, keyProduct] = payload.key
            .replace(' in ', ':')
            .split(':');
        }

        newControlsData = update(state.controlsData, {
          0: {
            data: {
              $apply: (data) =>
                data.map((d) => {
                  if (d.name === 'Country' && newState.view !== 'country') {
                    d.controls = d.controls.map((control) => ({
                      ...control,
                      active: control.name === country,
                    }));
                  } else {
                    d.controls = d.controls.map((control) => ({
                      ...control,
                      active: false,
                    }));
                  }
                  return d;
                }),
            },
          },
          1: {
            data: {
              $apply: (data) =>
                data.map((d) => {
                  if (d.name === 'Reference Market') {
                    d.controls = d.controls.map((control) => ({
                      ...control,
                      active: control.name === keyProduct + ':' + market,
                    }));
                  } else {
                    d.controls = d.controls.map((control) => ({
                      ...control,
                      active: false,
                    }));
                  }
                  return d;
                }),
            },
          },
          2: {
            data: {
              $apply: (data) =>
                data.map((d) => {
                  d.controls = d.controls.map((control) => ({
                    ...control,
                    active: false,
                  }));
                  return d;
                }),
            },
          },
        });

        // update the list of current filters
        newState.data.currentFilter = update(
          state.data.currentFilter,
          (currentFilter) => {
            newControlsData[0].data.forEach((data) => {
              currentFilter[data.name] = update(currentFilter[data.name], {
                $set:
                  'Country' === data.name && newState.view !== 'country'
                    ? [country]
                    : [],
              });
            });
            newControlsData[1].data.forEach((data) => {
              currentFilter[data.name] = update(currentFilter[data.name], {
                $set:
                  'Reference Market' === data.name
                    ? [keyProduct + ':' + market]
                    : [],
              });
            });
            newControlsData[2].data.forEach((data) => {
              currentFilter[data.name] = update(currentFilter[data.name], {
                $set: [],
              });
            });
            return currentFilter;
          }
        );

        // calculate actives values for header and footer
        const group_index = state.controlsData[0].data.findIndex(
          (d) => d.name === 'Country'
        );
        newState.actives = calculateActives(newState, newControlsData, 2, 0);
        newState.actives = calculateActives(
          newState,
          newControlsData,
          0,
          group_index
        );
        newState.actives = calculateActives(newState, newControlsData, 1, 7);

        if (newControlsData[3].data[7].controls.length < 5) {
          newControlsData[1].data.forEach((d) => (d.disabled = false));
          newControlsData[2].data.forEach((d) => (d.disabled = false));
          newControlsData[3].data[7].controls = dimensions;
          updateDimensions(newControlsData);
        }
        if (newState.view === 'country') {
          // select only Country dimension (select "Country" name, not title)
          const dim = newControlsData[3].data[7].controls[2].name;
          newState.dims = [dim];
          newControlsData[3].data[7].controls.forEach(
            (c, i) => (c.active = i === 2)
          );

          // disable reference market
          newControlsData[1].data[7].disabled = true;
        } else if (newState.view === 'market') {
          // select only Product Group 1 dimension or Corporation Group 1 for CHC
          let dimIndex = 16; // Product Group 1
          if (isCHC) {
            dimIndex = 13;
          }
          const dim = newControlsData[3].data[7].controls[dimIndex].name;
          newState.dims = [dim];
          newControlsData[3].data[7].controls.forEach(
            (c, i) => (c.active = i === dimIndex)
          );
        } else if (newState.view === 'portfolio') {
          // select only Category0 dimension or (Category1 + Category2) for CHC only
          if (isCHC) {
            const dimIndex1 = 9;
            const dimIndex2 = 8;
            newState.dims = [
              newControlsData[3].data[7].controls[dimIndex2].name,
              newControlsData[3].data[7].controls[dimIndex1].name,
            ];
            newControlsData[3].data[7].controls.forEach(
              (c, i) => (c.active = i === dimIndex1 || i === dimIndex2)
            );
          } else {
            const dimIndex = 10; // Category0
            const dim = newControlsData[3].data[7].controls[dimIndex].name;
            newState.dims = [dim];
            newControlsData[3].data[7].controls.forEach(
              (c, i) => (c.active = i === dimIndex)
            );
          }
        }
      } else {
        let tab_index = -1,
          group_index;

        // country dim
        if (
          state.controlsData[0].data.map((d) => d.name).includes(payload.dim)
        ) {
          tab_index = 0;
          group_index = state.controlsData[0].data.findIndex(
            (d) => d.name === payload.dim
          );
        }

        // category dim
        if (
          state.controlsData[1].data.map((d) => d.name).includes(payload.dim)
        ) {
          tab_index = 1;
          group_index = state.controlsData[1].data.findIndex(
            (d) => d.name === payload.dim
          );
        }

        // product dim
        if (
          state.controlsData[2].data.map((d) => d.name).includes(payload.dim)
        ) {
          tab_index = 2;
          group_index = state.controlsData[2].data.findIndex(
            (d) => d.name === payload.dim
          );
        }

        // remove all country filters
        // add product filter if key exists
        if (payload.data === 'country') {
          // product
          if (tab_index === 2) {
            newControlsData = update(state.controlsData, {
              0: {
                data: {
                  $apply: (data) =>
                    data.map((d) => {
                      d.controls = d.controls.map((control) => ({
                        ...control,
                        active: false,
                      }));
                      return d;
                    }),
                },
              },
              2: {
                data: {
                  $apply: (data) =>
                    data.map((d) => {
                      if (d.name === payload.dim) {
                        d.controls = d.controls.map((control) => ({
                          ...control,
                          active: control.name === payload.key,
                        }));
                      } else {
                        d.controls = d.controls.map((control) => ({
                          ...control,
                          active: false,
                        }));
                      }
                      return d;
                    }),
                },
              },
            });

            // update the list of current filters
            newState.data.currentFilter = update(
              state.data.currentFilter,
              (currentFilter) => {
                newControlsData[0].data.forEach((data) => {
                  currentFilter[data.name] = update(currentFilter[data.name], {
                    $set: [],
                  });
                });
                newControlsData[2].data.forEach((data) => {
                  currentFilter[data.name] = update(currentFilter[data.name], {
                    $set: payload.dim === data.name ? [payload.key] : [],
                  });
                });
                return currentFilter;
              }
            );

            // calculate actives values for header and footer
            newState.actives = calculateActives(
              newState,
              newControlsData,
              0,
              0
            );
            newState.actives = calculateActives(
              newState,
              newControlsData,
              2,
              group_index
            );
          }
          // category
          if (tab_index === 1) {
            newControlsData = update(state.controlsData, {
              0: {
                data: {
                  $apply: (data) =>
                    data.map((d) => {
                      d.controls = d.controls.map((control) => ({
                        ...control,
                        active: false,
                      }));
                      return d;
                    }),
                },
              },
              2: {
                data: {
                  $apply: (data) =>
                    data.map((d) => {
                      d.controls = d.controls.map((control) => ({
                        ...control,
                        active: false,
                      }));
                      return d;
                    }),
                },
              },
              1: {
                data: {
                  $apply: (data) =>
                    data.map((d) => {
                      if (d.name === payload.dim) {
                        d.controls = d.controls.map((control) => ({
                          ...control,
                          active: control.name === payload.key,
                        }));
                      } else {
                        d.controls = d.controls.map((control) => ({
                          ...control,
                          active: false,
                        }));
                      }
                      return d;
                    }),
                },
              },
            });

            // update the list of current filters
            newState.data.currentFilter = update(
              state.data.currentFilter,
              (currentFilter) => {
                newControlsData[0].data.forEach((data) => {
                  currentFilter[data.name] = update(currentFilter[data.name], {
                    $set: [],
                  });
                });
                newControlsData[2].data.forEach((data) => {
                  currentFilter[data.name] = update(currentFilter[data.name], {
                    $set: [],
                  });
                });
                newControlsData[1].data.forEach((data) => {
                  currentFilter[data.name] = update(currentFilter[data.name], {
                    $set: payload.dim === data.name ? [payload.key] : [],
                  });
                });
                return currentFilter;
              }
            );

            // calculate actives values for header and footer
            newState.actives = calculateActives(
              newState,
              newControlsData,
              0,
              0
            );
            newState.actives = calculateActives(
              newState,
              newControlsData,
              2,
              0
            );
            newState.actives = calculateActives(
              newState,
              newControlsData,
              1,
              group_index
            );
          }

          // select only Country dimension (select "Country" name, not title)
          if (newControlsData[3].data[7].controls.length < 5) {
            newControlsData[1].data.forEach((d) => (d.disabled = false));
            newControlsData[2].data.forEach((d) => (d.disabled = false));
            newControlsData[3].data[7].controls = dimensions;
            updateDimensions(newControlsData);
          }
          const dim = newControlsData[3].data[7].controls[2].name;
          newState.dims = [dim];
          newControlsData[3].data[7].controls.forEach(
            (c, i) => (c.active = i === 2)
          );

          // disable reference market
          newControlsData[1].data[7].disabled = true;
        }

        if (payload.data === 'market') {
          // country
          if (tab_index === 0) {
            newControlsData = update(state.controlsData, {
              2: {
                data: {
                  $apply: (data) =>
                    data.map((d) => {
                      d.controls = d.controls.map((control) => ({
                        ...control,
                        active: false,
                      }));
                      return d;
                    }),
                },
              },
              0: {
                data: {
                  $apply: (data) =>
                    data.map((d) => {
                      if (d.name === payload.dim) {
                        d.controls = d.controls.map((control) => ({
                          ...control,
                          active: control.name === payload.key,
                        }));
                      } else {
                        d.controls = d.controls.map((control) => ({
                          ...control,
                          active: false,
                        }));
                      }
                      return d;
                    }),
                },
              },
            });

            // update the list of current filters
            newState.data.currentFilter = update(
              state.data.currentFilter,
              (currentFilter) => {
                newControlsData[2].data.forEach((data) => {
                  currentFilter[data.name] = update(currentFilter[data.name], {
                    $set: [],
                  });
                });
                newControlsData[0].data.forEach((data) => {
                  currentFilter[data.name] = update(currentFilter[data.name], {
                    $set: payload.dim === data.name ? [payload.key] : [],
                  });
                });
                return currentFilter;
              }
            );

            // calculate actives values for header and footer
            newState.actives = calculateActives(
              newState,
              newControlsData,
              2,
              0
            );
            newState.actives = calculateActives(
              newState,
              newControlsData,
              0,
              group_index
            );
          }
          // category
          else if (tab_index === 1) {
            newControlsData = update(state.controlsData, {
              2: {
                data: {
                  $apply: (data) =>
                    data.map((d) => {
                      d.controls = d.controls.map((control) => ({
                        ...control,
                        active: false,
                      }));
                      return d;
                    }),
                },
              },
              0: {
                data: {
                  $apply: (data) =>
                    data.map((d) => {
                      d.controls = d.controls.map((control) => ({
                        ...control,
                        active: false,
                      }));
                      return d;
                    }),
                },
              },
              1: {
                data: {
                  $apply: (data) =>
                    data.map((d) => {
                      if (d.name === payload.dim) {
                        d.controls = d.controls.map((control) => ({
                          ...control,
                          active: control.name === payload.key,
                        }));
                      } else {
                        d.controls = d.controls.map((control) => ({
                          ...control,
                          active: false,
                        }));
                      }
                      return d;
                    }),
                },
              },
            });

            // update the list of current filters
            newState.data.currentFilter = update(
              state.data.currentFilter,
              (currentFilter) => {
                newControlsData[2].data.forEach((data) => {
                  currentFilter[data.name] = update(currentFilter[data.name], {
                    $set: [],
                  });
                });
                newControlsData[0].data.forEach((data) => {
                  currentFilter[data.name] = update(currentFilter[data.name], {
                    $set: [],
                  });
                });
                newControlsData[1].data.forEach((data) => {
                  currentFilter[data.name] = update(currentFilter[data.name], {
                    $set: payload.dim === data.name ? [payload.key] : [],
                  });
                });
                return currentFilter;
              }
            );

            // calculate actives values for header and footer
            newState.actives = calculateActives(
              newState,
              newControlsData,
              2,
              0
            );
            newState.actives = calculateActives(
              newState,
              newControlsData,
              0,
              0
            );
            newState.actives = calculateActives(
              newState,
              newControlsData,
              1,
              group_index
            );
          }

          // select only Product Group 1 dimension or Corporation Group 1 for CHC
          if (newControlsData[3].data[7].controls.length < 5) {
            newControlsData[1].data.forEach((d) => (d.disabled = false));
            newControlsData[2].data.forEach((d) => (d.disabled = false));
            newControlsData[3].data[7].controls = dimensions;
            updateDimensions(newControlsData);
          }
          let dimIndex = 16; // Product Group 1
          if (isCHC) {
            dimIndex = 13;
          }
          const dim = newControlsData[3].data[7].controls[dimIndex].name;
          newState.dims = [dim];
          newControlsData[3].data[7].controls.forEach(
            (c, i) => (c.active = i === dimIndex)
          );

          // enable reference market
          newControlsData[1].data[7].disabled = false;
        }

        if (payload.data === 'portfolio') {
          // country
          if (tab_index === 0) {
            newControlsData = update(state.controlsData, {
              2: {
                data: {
                  $apply: (data) =>
                    data.map((d) => {
                      if (d.name === 'Corporation Group 1') {
                        d.controls = d.controls.map((control) => ({
                          ...control,
                          active: control.name === SANOFI_CORP,
                        }));
                      } else {
                        d.controls = d.controls.map((control) => ({
                          ...control,
                          active: false,
                        }));
                      }
                      return d;
                    }),
                },
              },
              0: {
                data: {
                  $apply: (data) =>
                    data.map((d) => {
                      if (d.name === payload.dim) {
                        d.controls = d.controls.map((control) => ({
                          ...control,
                          active: control.name === payload.key,
                        }));
                      } else {
                        d.controls = d.controls.map((control) => ({
                          ...control,
                          active: false,
                        }));
                      }
                      return d;
                    }),
                },
              },
              1: {
                data: {
                  $apply: (data) =>
                    data.map((d) => {
                      d.controls = d.controls.map((control) => ({
                        ...control,
                        active: false,
                      }));
                      return d;
                    }),
                },
              },
            });

            // update the list of current filters
            newState.data.currentFilter = update(
              state.data.currentFilter,
              (currentFilter) => {
                newControlsData[2].data.forEach((data) => {
                  currentFilter[data.name] = update(currentFilter[data.name], {
                    $set:
                      'Corporation Group 1' === data.name ? [SANOFI_CORP] : [],
                  });
                });
                newControlsData[0].data.forEach((data) => {
                  currentFilter[data.name] = update(currentFilter[data.name], {
                    $set: payload.dim === data.name ? [payload.key] : [],
                  });
                });
                newControlsData[1].data.forEach((data) => {
                  currentFilter[data.name] = update(currentFilter[data.name], {
                    $set: [],
                  });
                });
                return currentFilter;
              }
            );

            // calculate actives values for header and footer
            newState.actives = calculateActives(
              newState,
              newControlsData,
              2,
              1
            );
            newState.actives = calculateActives(
              newState,
              newControlsData,
              1,
              0
            );
            newState.actives = calculateActives(
              newState,
              newControlsData,
              0,
              group_index
            );
          } else {
            newControlsData = update(state.controlsData, {
              2: {
                data: {
                  $apply: (data) =>
                    data.map((d) => {
                      if (d.name === 'Corporation Group 1') {
                        d.controls = d.controls.map((control) => ({
                          ...control,
                          active: control.name === SANOFI_CORP,
                        }));
                      } else {
                        d.controls = d.controls.map((control) => ({
                          ...control,
                          active: false,
                        }));
                      }
                      return d;
                    }),
                },
              },
              0: {
                data: {
                  $apply: (data) =>
                    data.map((d) => {
                      d.controls = d.controls.map((control) => ({
                        ...control,
                        active: false,
                      }));
                      return d;
                    }),
                },
              },
              1: {
                data: {
                  $apply: (data) =>
                    data.map((d) => {
                      d.controls = d.controls.map((control) => ({
                        ...control,
                        active: false,
                      }));
                      return d;
                    }),
                },
              },
            });

            // update the list of current filters
            newState.data.currentFilter = update(
              state.data.currentFilter,
              (currentFilter) => {
                newControlsData[2].data.forEach((data) => {
                  currentFilter[data.name] = update(currentFilter[data.name], {
                    $set:
                      'Corporation Group 1' === data.name ? [SANOFI_CORP] : [],
                  });
                });
                newControlsData[0].data.forEach((data) => {
                  currentFilter[data.name] = update(currentFilter[data.name], {
                    $set: [],
                  });
                });
                newControlsData[1].data.forEach((data) => {
                  currentFilter[data.name] = update(currentFilter[data.name], {
                    $set: [],
                  });
                });
                return currentFilter;
              }
            );

            // calculate actives values for header and footer
            newState.actives = calculateActives(
              newState,
              newControlsData,
              2,
              1
            );
            newState.actives = calculateActives(
              newState,
              newControlsData,
              1,
              0
            );
            newState.actives = calculateActives(
              newState,
              newControlsData,
              0,
              group_index
            );
          }

          // select only Category0 dimension
          if (newControlsData[3].data[7].controls.length < 5) {
            newControlsData[1].data.forEach((d) => (d.disabled = false));
            newControlsData[2].data.forEach((d) => (d.disabled = false));
            newControlsData[3].data[7].controls = dimensions;
            updateDimensions(newControlsData);
          }
          // select only Category0 dimension or (Category1 + Category2) for CHC only
          if (isCHC) {
            const dimIndex1 = 9;
            const dimIndex2 = 8;
            newState.dims = [
              newControlsData[3].data[7].controls[dimIndex2].name,
              newControlsData[3].data[7].controls[dimIndex1].name,
            ];
            newControlsData[3].data[7].controls.forEach(
              (c, i) => (c.active = i === dimIndex1 || i === dimIndex2)
            );
          } else {
            const dimIndex = 10; // Category0
            const dim = newControlsData[3].data[7].controls[dimIndex].name;
            newState.dims = [dim];
            newControlsData[3].data[7].controls.forEach(
              (c, i) => (c.active = i === dimIndex)
            );
          }

          // enable reference market
          newControlsData[1].data[7].disabled = false;
        }

        if (payload.data === 'executive') {
          newControlsData = update(state.controlsData, {
            2: {
              data: {
                $apply: (data) =>
                  data.map((d) => {
                    d.controls = d.controls.map((control) => ({
                      ...control,
                      active: false,
                    }));
                    return d;
                  }),
              },
            },
            1: {
              data: {
                $apply: (data) =>
                  data.map((d) => {
                    d.controls = d.controls.map((control) => ({
                      ...control,
                      active: false,
                    }));
                    return d;
                  }),
              },
            },
          });

          newState.data.currentFilter = update(
            state.data.currentFilter,
            (currentFilter) => {
              newControlsData[1].data.forEach((data) => {
                currentFilter[data.name] = update(currentFilter[data.name], {
                  $set: [],
                });
              });
              newControlsData[2].data.forEach((data) => {
                currentFilter[data.name] = update(currentFilter[data.name], {
                  $set: [],
                });
              });
              return currentFilter;
            }
          );

          // calculate actives values for header and footer
          newState.actives = calculateActives(newState, newControlsData, 1, 0);
          newState.actives = calculateActives(newState, newControlsData, 2, 0);

          // select only Category0 dimension
          newControlsData[1].data.forEach((d) =>
            ![0, 7].includes(d.index) ? (d.disabled = true) : null
          );
          newControlsData[2].data.forEach((d) => (d.disabled = true));
          newControlsData[3].data[7].controls = [
            { name: 'Country', index: 0, active: true, disabled: true },
            { name: 'Category5', index: 1, active: true },
            {
              name: 'Product Portfolio',
              index: 2,
              title: 'Reference Market',
              active: true,
              disabled: true,
            },
            { name: 'Product Group 1', index: 3, active: false },
          ];

          const dims = newControlsData[3].data[7].controls
            .filter((d) => d.active)
            .map((d) => d.name);
          newState.dims = dims;
        }
      }

      // display Default metric demand if new view == executive, and hide if any other view
      const defaultMetric = newControlsData[3].data[1].controls.find(
        (d) => d.name === 'Default'
      );
      defaultMetric.hidden = payload.data !== 'executive';
      if (payload.data === 'executive') {
        // if switch to executive view
        newControlsData[3].data[1].controls.forEach(
          (d, i) => (d.active = d.name === 'Default')
        );
      }
      if (
        state.view === 'executive' &&
        payload.data !== 'executive' &&
        defaultMetric.active
      ) {
        // if move out from executive view
        // payload.dim in this case is default_metric
        if (payload.dim) {
          newControlsData[3].data[1].controls.forEach(
            (d) => (d.active = d.name === payload.dim.replace('_QRT', ''))
          );
        } else {
          newControlsData[3].data[1].controls.forEach(
            (d, i) => (d.active = i === 0)
          );
        }
      }

      // period aligned
      if (newState.view === 'market') {
        if (newControlsData[3].data[6].controls[1].active) {
          newControlsData[3].data[6].controls[0].active = true;
          newControlsData[3].data[6].controls[1].active = false;
          newControlsData[3].data[6].controls[1].disabled = true;
        } else {
          newControlsData[3].data[6].controls[1].disabled = true;
        }
        // newControlsData[3].data[6].controls.forEach((d, i) => {
        //   if (i === 0) d.active = true;
        //   if (i === 1) {
        //     d.active = false;
        //     d.disabled = true
        //   }
        // });
      } else {
        newControlsData[3].data[6].controls.forEach((d, i) => {
          if (i === 1) d.disabled = false;
        });
      }

      newState.controlsData = newControlsData;

      // // recalculate last date
      // newState.dateEnd = calculateLastDate(newState);
      // newState.dateEndFinance = calculateLastDateFinance(newState);

      newState.defaultKPIs = update(newState.defaultKPIs, {
        0: {
          kpis: {
            $set: getKPIs(newState, newState.controlsData),
          },
        },
        1: {
          kpis: {
            $set: getCustomKPIs(newState, newState.controlsData),
          },
        },
      });

      newState.charts = getCharts(newState);

      return newState;
    }
    case actions.DID_SELECT_VIEW: {
      const newState = { ...state };

      // recalculate last date
      newState.dateEnd = calculateLastDate(newState);

      // update highlights
      updateHighlights(newState);

      newState.tableData = getTableData(newState);
      newState.selectedId = findSelectedId(newState);

      newState.chartControls = getChartControls(newState);
      newState.chartData = getChartData(newState);

      return newState;
    }

    case actions.PLUS: {
      const newState = {
        ...state,
        ['charts']: update(state.charts, {
          [state.charts.findIndex((d) => d.id === payload)]: {
            number: {
              $apply: (d) => d + 1,
            },
          },
        }),
      };

      newState.chartData = getChartData(newState);

      return newState;
    }

    case actions.MINUS: {
      const newState = {
        ...state,
        ['charts']: update(state.charts, {
          [state.charts.findIndex((d) => d.id === payload)]: {
            number: {
              $apply: (d) => d - 1 || 1,
            },
          },
        }),
      };

      newState.chartData = getChartData(newState);

      return newState;
    }

    case actions.CHANGE_CHART_CONTROL: {
      const newState = {
        ...state,
        ['chartControls']: update(state.chartControls, {
          [payload.name]: {
            [payload.type]: {
              $apply: (d) =>
                ['finance', 'forecast'].includes(payload.name)
                  ? { ...d, value: payload.value }
                  : payload.value,
            },
          },
        }),
      };

      // MSE_Comp
      if (payload.name === 'MSE_Comp' && payload.type === 'selectedItem1') {
        newState.chartControls[payload.name]['selectedItem2'] = null;
      }

      newState.charts = getCharts(newState);
      // newState.aggrData = getAggregationData(newState, newState.controlsData);
      newState.chartData = getChartData(newState);

      return newState;
    }

    case actions.CHANGE_SELECTED_DATE: {
      const newState = {
        ...state,
        ['charts']: update(state.charts, {
          [state.charts.findIndex((d) => d.id === payload.id)]: {
            selectedDate: {
              $set: state.dateFormat(payload.date),
            },
          },
        }),
      };

      newState.chartData = getChartData(newState);

      return newState;
    }

    case actions.FIRST_HEADER_ICON: {
      return {
        ...state,
        isTooltip: !payload,
      };
    }

    case actions.LAST_HEADER_ICON: {
      return {
        ...state,
        isShowRP: !payload,
      };
    }

    case actions.CONTROLS: {
      // handle dimensions index correctly
      if (payload.tab_index === 3 && payload.group_index === 7) {
        payload.index = state.controlsData[payload.tab_index].data[
          payload.group_index
        ].controls.find((d) => d.index === payload.index).index;
      }

      const newControlsData =
        payload.index < 0
          ? update(state.controlsData, {
              [payload.tab_index]: {
                data: {
                  [payload.group_index]: {
                    customDate: {
                      active: {
                        $set: true,
                      },
                      date: {
                        $apply: (d) => payload.date || d,
                      },
                    },
                  },
                },
              },
            })
          : update(state.controlsData, {
              [payload.tab_index]: {
                data: {
                  [payload.group_index]: {
                    controls: {
                      [payload.index]: {
                        active: {
                          $set: payload.active,
                        },
                      },
                    },
                  },
                },
              },
            });

      const newState = {
        ...state,
        controlsData: newControlsData,
      };

      // country or market or product
      if (payload.tab_index < 3) {
        if (!window.keys.metaKey && !window.keys.ctrlKey) {
          newControlsData[payload.tab_index].data[
            payload.group_index
          ].controls.forEach(
            (d, i) => (d.active = i === payload.index ? d.active : false)
          );
        }

        if (window.keys.shiftKey) {
          newControlsData[payload.tab_index].data[
            payload.group_index
          ].controls.forEach((d, i) => (d.active = i !== payload.index));
        }

        const dim =
          newControlsData[payload.tab_index].data[payload.group_index].name;
        const filters = newControlsData[payload.tab_index].data[
          payload.group_index
        ].controls
          .filter((c) => c.active)
          .map((c) => c.name);

        // clear filters for all lower dimensions
        const toResetDims = [];

        newControlsData[payload.tab_index].data.forEach((d, i) => {
          if (
            d.controls.length &&
            i > payload.group_index &&
            state.data.currentFilter[d.name].length
          ) {
            d.controls.forEach((c) => (c.active = false));
            toResetDims.push(d.name);
          }
        });

        // update the list of current filters
        const newCurrentFilter = Object.assign({}, state.data.currentFilter);
        newCurrentFilter[dim] = filters;
        toResetDims.forEach((dim) => (newCurrentFilter[dim] = []));

        newState.data = update(state.data, {
          currentFilter: {
            $set: newCurrentFilter,
          },
        });
      }

      // data source
      if (payload.tab_index === 3 && payload.group_index === 0) {
        newControlsData[payload.tab_index].data[
          payload.group_index
        ].controls.forEach((d, i) => (d.active = i === payload.index));
        const frequency = newControlsData[payload.tab_index].data[
          payload.group_index
        ].controls.filter((d) => d.active)[0];
        if (frequency.name === '0') {
          // Board-Time Aggr.
          newControlsData[3].data[3].controls[0].active = true; // MTH
          newControlsData[3].data[3].controls[0].disabled = false; // MTH

          // Chart-Time Aggr.
          newControlsData[3].data[4].controls[0].active = true; // MTH
          newControlsData[3].data[4].controls[1].active = false; // RQRT
          newControlsData[3].data[4].controls[0].disabled = false; //MTH
        } else {
          // Board-Time Aggr.
          if (newControlsData[3].data[3].controls[0].active) {
            newControlsData[3].data[3].controls[0].active = false; // MTH
            newControlsData[3].data[3].controls[1].active = true; // RQRT
          }
          newControlsData[3].data[3].controls[0].disabled = true;

          // Chart-Time Aggr.
          if (newControlsData[3].data[4].controls[0].active) {
            newControlsData[3].data[4].controls[0].active = false; // MTH
            newControlsData[3].data[4].controls[1].active = true; // RQRT
          }
          newControlsData[3].data[4].controls[0].disabled = true; //MTH
        }
      }

      // metrics
      if (payload.tab_index === 3 && payload.group_index === 1) {
        newControlsData[payload.tab_index].data[
          payload.group_index
        ].controls.forEach((d, i) => (d.active = i === payload.index));
      }

      // metrics - finance
      if (payload.tab_index === 3 && payload.group_index === 8) {
        newControlsData[payload.tab_index].data[
          payload.group_index
        ].controls.forEach((d, i) => (d.active = i === payload.index));
      }

      // charts time aggregation
      if (payload.tab_index === 3 && payload.group_index === 4) {
        newControlsData[payload.tab_index].data[
          payload.group_index
        ].controls.forEach((d, i) => (d.active = i === payload.index));
      }

      // charts historical data
      if (payload.tab_index === 3 && payload.group_index === 5) {
        newControlsData[payload.tab_index].data[
          payload.group_index
        ].controls.forEach((d, i) => (d.active = i === payload.index));
      }

      // period aligned
      if (payload.tab_index === 3 && payload.group_index === 6) {
        newControlsData[payload.tab_index].data[
          payload.group_index
        ].controls.forEach((d, i) => (d.active = i === payload.index));

        newControlsData[payload.tab_index].data[
          payload.group_index
        ].customDate.active = payload.index < 0;
      }

      // Market -> Dimensions
      if (payload.tab_index === 3 && payload.group_index === 7) {
        const control =
          newControlsData[payload.tab_index].data[payload.group_index].controls[
            payload.index
          ];
        const dim = control.name;

        newState.dims = state.dims.slice(0);

        if (!control.active) {
          if (newState.dims.length > 1) {
            newState.dims = newState.dims.filter((d) => d !== dim);
          } else {
            newControlsData[payload.tab_index].data[
              payload.group_index
            ].controls[payload.index].active = true;
          }
        } else {
          if (newState.dims.indexOf(dim) === -1) {
            newState.dims.push(dim);
            // sort dims
            const controls = newControlsData[payload.tab_index].data[
              payload.group_index
            ].controls
              .map((d) => d.name)
              .reduce((map, c, i) => {
                map[c] = i;
                return map;
              }, {});

            newState.dims = newState.dims.sort((a, b) =>
              controls[a] > controls[b] ? 1 : -1
            );
          }
        }
      }

      // recalculate last date
      // newState.dateEnd = calculateLastDate(newState);
      // newState.dateEndFinance = calculateLastDateFinance(newState);

      // calculate actives values for header and footer
      newState.actives = calculateActives(
        newState,
        newControlsData,
        payload.tab_index,
        payload.group_index
      );

      newState.defaultKPIs = update(newState.defaultKPIs, {
        0: {
          kpis: {
            $set: getKPIs(newState, newControlsData),
          },
        },
        1: {
          kpis: {
            $set: getCustomKPIs(newState, newControlsData),
          },
        },
      });

      newState.charts = getCharts(newState);

      return newState;
    }
    case actions.DID_CONTROLS: {
      const newState = { ...state };

      // recalculate last date
      newState.dateEnd = calculateLastDate(newState);

      // update control highlights
      updateHighlights(newState);

      newState.tableData = getTableData(newState);

      if (
        !(
          payload.tab_index === 3 &&
          [0, 3, 4, 5, 6, 8].includes(payload.group_index)
        )
      ) {
        newState.selectedId = findSelectedId(newState);
      }

      newState.chartControls = getChartControls(newState);
      newState.chartData = getChartData(newState);

      return newState;
    }

    case actions.CONTROL_RESET: {
      const newControlsData =
        payload.tab_index === -1
          ? update(state.controlsData, {
              0: {
                data: {
                  $apply: (data) =>
                    data.map((datum) => ({
                      ...datum,
                      controls: datum.controls.map((control) => ({
                        ...control,
                        active: false,
                      })),
                    })),
                },
              },
              1: {
                data: {
                  $apply: (data) =>
                    data.map((datum) => ({
                      ...datum,
                      controls: datum.controls.map((control) => ({
                        ...control,
                        active: false,
                      })),
                    })),
                },
              },
              2: {
                data: {
                  $apply: (data) =>
                    data.map((datum) => ({
                      ...datum,
                      controls: datum.controls.map((control) => ({
                        ...control,
                        active: false,
                      })),
                    })),
                },
              },
            })
          : update(state.controlsData, {
              [payload.tab_index]: {
                data: {
                  [payload.group_index]: {
                    controls: {
                      $apply: (controls) =>
                        controls.map((control) => ({
                          ...control,
                          active: false,
                        })),
                    },
                  },
                },
              },
            });

      const newState = {
        ...state,
        controlsData: newControlsData,
      };

      if (payload.tab_index < 0) {
        const { currentFilter } = newState.data;
        Object.keys(currentFilter).forEach((dim) => {
          if (currentFilter[dim].length) {
            newState.data.currentFilter[dim] = [];
          }
        });
      }

      if (payload.tab_index >= 0 && payload.tab_index < 3) {
        const dim =
          newControlsData[payload.tab_index].data[payload.group_index].name;

        // update the list of current filters
        newState.data = update(state.data, {
          currentFilter: {
            [dim]: {
              $set: [],
            },
          },
        });
      }

      if (payload.tab_index === 1) {
        newControlsData[payload.tab_index].data.forEach(
          (d) => (d.disabled = false)
        );
      }

      // recalculate last date
      // newState.dateEnd = calculateLastDate(newState);
      // newState.dateEndFinance = calculateLastDateFinance(newState);

      // calculate actives values for header and footer
      if (payload.tab_index === -1) {
        newState.actives = calculateActives(newState, newControlsData, 0, 0);
        newState.actives = calculateActives(newState, newControlsData, 1, 0);
        newState.actives = calculateActives(newState, newControlsData, 2, 0);
      } else {
        newState.actives = calculateActives(
          newState,
          newControlsData,
          payload.tab_index,
          payload.group_index
        );
      }

      newState.defaultKPIs = update(newState.defaultKPIs, {
        0: {
          kpis: {
            $set: getKPIs(newState, newControlsData),
          },
        },
        1: {
          kpis: {
            $set: getCustomKPIs(newState, newControlsData),
          },
        },
      });

      newState.charts = getCharts(newState);

      return newState;
    }
    case actions.DID_CONTROL_RESET: {
      const newState = { ...state };

      // recalculate last date
      newState.dateEnd = calculateLastDate(newState);

      // update control highlights
      updateHighlights(newState);

      newState.tableData = getTableData(newState);
      newState.selectedId = findSelectedId(newState);

      newState.chartControls = getChartControls(newState);
      newState.chartData = getChartData(newState);

      return newState;
    }

    default:
      return state;
  }
};
