import React from 'react';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const formData = [
  [
    {
      type: 'text',
      name: {
        label: 'Name',
        input: 'name',
      },
    },
    {
      type: 'text',
      name: {
        label: 'Description',
        input: 'description',
      },
    },
  ], [
    {
      required: true,
      type: 'text',
      name: {
        label: 'Name',
        input: 'name',
      },
    },
    {
      required: true,
      type: 'text',
      disabled: true,
      name: {
        label: 'Datasource',
        input: 'datasource_id',
      },
      inputType: 'select',
    },
  ]
];

const useStyles = makeStyles((theme) => ({
  select_container: {
    width: '100%'
  },
  select_root: {
    paddingTop: '10.5px',
    paddingBottom: '10.5px',
  },
  label: {
    transform: 'translate(14px, 12px) scale(1)',
  },
  textField: {
    width: "100%"
  }
}));

const FormikSample = React.forwardRef((props, ref) => {
  const { index, data, datasources, submit } = props;
  const classes = useStyles();

  const handleSubmit = (values) => {
    submit(values, data);
  };

  const initialValues = {};
  formData[index].forEach((f) => {
    initialValues[f.name.input] = data !== null ? data[f.name.input] : '';
  });

  return (
    <Formik
      innerRef={ref}
      initialValues={initialValues}
      // validate={validate}
      onSubmit={(values, actions) => {
        handleSubmit(values);
      }}>
      {(props) => {
        const { values, setFieldValue, handleChange, handleSubmit } = props;
        return (
          <Form className="uk-form-stacked" onSubmit={handleSubmit}>
            {formData[index].map((m, idx) =>
              m.inputType && m.inputType === 'select'
                ? (
                  <div key={idx} className="mb-3">
                    <FormControl
                      required={m.required}
                      variant="outlined"
                      disabled={m.disabled && data !== null ? m.disabled : false}
                      className={classes.select_container}>
                      <InputLabel id={m.name.input} className={classes.label}>
                        {m.name.label}
                      </InputLabel>
                      <Select
                        labelId={m.name.input}
                        id={'demo-simple-select-outlined-' + m.name.input}
                        value={values[m.name.input]}
                        onChange={(e) =>
                          setFieldValue(m.name.input, e.target.value)
                        }
                        label={m.name.label}
                        classes={{
                          root: classes.select_root,
                        }}>
                        {datasources.map((dtsrc) => (
                          <MenuItem
                            key={dtsrc.name || dtsrc.datasource_id}
                            value={dtsrc.name || dtsrc.datasource_id}>
                            {dtsrc.friendlyName || dtsrc.config.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                    <div key={idx} className="mb-3">
                      <TextField
                        className={classes.textField}
                        required={m.required}
                        type={m.type}
                        name={m.name.input}
                        id={'outlined-basic-' + m.name.input}
                        size="small"
                        label={m.name.label}
                        value={values[m.name.input]}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </div>
                  )
            )}
          </Form>
        );
      }}
    </Formik>
  );
});

export default FormikSample;
