import React from "react";
import ReactDOM from "react-dom";
import * as d3 from "d3";
import numeral from "numeral";
import colors from "./../../store/reducers/config/colors";
import "./Chart.scss";

const defaultProps = {
  margin: { top: 15, right: 30, bottom: 90, left: 50 },
  width: 540,
  height: 280
};

const selectFormat = (f, d) => {
  const format = Math.abs(d) < 1000 ? numeral(d).format("0.[0]") : numeral(d).format("0.0a");
  switch (f) {
    case "pts":
      return numeral(d).format("0.0") + "pts";
    case "percent":
      return numeral(d / 100).format("0.0%");
    default:
      return format.toUpperCase();
  }
};

const tickValues = domain => domain.length > 25
  ? domain.filter((d, i) => i % 3 === 2)
  : (domain.length > 13
    ? domain.filter((d, i) => i % 2 === 1)
    : domain)

class Chart extends React.Component {
  constructor(props) {
    super(props);

    this.type = "bar-line";

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    const { 
      data, 
      id, 
      type, 
      format, 
      chartType, 
      margin, 
      secondYAxis, 
      secondYAxisFormat, 
      yDomain,
      showLabels, 
      selectedDate, 
      full_screen
    } = this.props;
    
    this.type = type;
    this.format = format;
    this.secondYAxisFormat = secondYAxisFormat || format;
    this.secondYAxis = secondYAxis;
    this.showLabels = showLabels;
    if (['bar-line', 'stacked', 'stacked-line', 'multiline'].includes(type)) {
      this.showLabels = !window.matchMedia("(max-width : 1024px)").matches && full_screen;
    }
    this.id = id;
    this.chartType = chartType || "sales";
    this.selectedDate = selectedDate;
    this.yDomain = yDomain;

    this.axisFontSize = full_screen ? '11px' : '8px';
    this.labelFontSize = full_screen ? '12px' : '10px';

    const width = this.refs[`chart_${id}`].parentNode.clientWidth;
    const height = this.refs[`chart_${id}`].parentNode.clientHeight - 22;

    this.width = width || defaultProps.width;
    this.height = height || defaultProps.height;
    this.margin = margin || defaultProps.margin;

    this.innerWidth = this.width - this.margin.left - this.margin.right;
    this.innerHeight = this.height - this.margin.top - this.margin.bottom + (full_screen ? -10 : 0);
    if (type === 'horizontal-bar') {
      this.x = d3.scaleLinear().rangeRound([0, this.innerWidth]);
      this.y = d3
        .scaleBand()
        .padding(0.2)
        .rangeRound([0, this.innerHeight]);
    } else {
      this.y = d3.scaleLinear().rangeRound([this.innerHeight, 0]);
      this.x = d3
        .scaleBand()
        .padding(0.2)
        .rangeRound([0, this.innerWidth]);
    }
    
    // secondYAxis
    if (this.secondYAxis) this.y1 = d3.scaleLinear().rangeRound([this.innerHeight, 0]);
    this.center = d3.scaleLinear().range([0, this.innerWidth]);

    this.defined = d => ['sales', 'wk_sales', 'finance'].includes(this.chartType) && !['DMS'].includes(this.id)
      ? !isNaN(d.value) && d.value !== null && d.value !== 0
      : !isNaN(d.value) && d.value !== null;

    this.initial(data);
  }

  getStackedData = data => {
    if (!data.length) return [];

    const isSingle = data.length === 1;
    let arrValues = [];
    let causes = [];
    data.forEach((f, i) => {
      causes.push(f.name);
      if (isSingle) causes.push("_zeros"); // fix bug for a single item in d3.stackOffsetDiverging

      f.data.forEach((ff, ii) => {
        if (isNaN(ff.value) || ff.value === null) {
          ff.value = 0;
        }
        const obj = {
          [f.name]: ff.value,
          date: ff.date
        };
        
        if (isSingle) obj["_zeros"] = 0; // fix bug for a single item in d3.stackOffsetDiverging
        
        if (i === 0) {
          arrValues.push(obj);
        } else {
          if (!arrValues[ii]) arrValues[ii] = { date: ff.date };
          arrValues[ii][f.name] = ff.value;
        }
      });
    });
    arrValues.forEach(d => causes.forEach(c => d[c] = typeof d[c] === 'undefined' ? 0 : d[c]));

    const layers = d3
      .stack()
      .keys(causes)
      .offset(d3.stackOffsetDiverging)(arrValues);

    if (isSingle) {
      layers[0].color = data[0].color;
    } else {
      layers.forEach((l, i) => l.color = data[i].color);
    }
    
    return layers;
  };

  prepareData = (data, all_data) => {
    if (!data.length) return;

    const { type, chartType } = this;

    const getYDomain = (data) => {
      if (!data.length) return [0, 0];

      const arrMaxValues = data.map(series => {
        return d3.max(series.data, function(d) {
          return d.value || -Infinity;
        });
      });
      const maxValue = Math.max.apply(null, arrMaxValues);

      const arrMinValues = data.map(series => {
        return d3.min(series.data, function(d) {
          return d.value || Infinity;
        });
      });
      const minValue = Math.min.apply(null, arrMinValues);

      const yDomain = (type === 'multiline' && chartType !== 'launch') ? [minValue, maxValue] : [Math.min(0, minValue), maxValue];
      const delta = 0.1 * Math.abs(yDomain[1] - yDomain[0]); 
      
      return [yDomain[0] === 0 ? yDomain[0] : yDomain[0] - delta, yDomain[1] + delta];
    }

    if (type === "stacked" || type === 'stacked-line') {
      let arrValues = [];
      data.forEach(f => {
        f.forEach(ff => {
          arrValues.push(ff[0]);
          arrValues.push(ff[1]);
        });
      });

      this.x.domain(
        data[0].map(d => {
          return d.data.date;
        })
      );

      const yDomain = [
        Math.min(0, Math.min.apply(null, arrValues)),
        Math.max.apply(null, arrValues)
      ];
      const delta = 0.1 * Math.abs(yDomain[1] - yDomain[0]);
      this.y.domain([yDomain[0] < 0 ? yDomain[0] - delta : yDomain[0], yDomain[1] + delta]);
      if (this.yDomain) this.y.domain(this.yDomain);

      if (all_data && this.secondYAxis) {
        this.y1.domain(getYDomain(all_data.filter(d => d.secondYAxis))); 
      }
    } 
    
    // horizontal
    else if (type === 'horizontal-bar') {
      // x domain
      const maxValue = d3.max(data[0].data, d => d.value || 0);
      this.x.domain([0, 1.1 * maxValue]);
      
      // y domain
      let yDomain = [];
      data.forEach(series => {
        if (series.domain) {
          yDomain = yDomain.concat(series.domain)
        } else {
          series.data.forEach(d => (d.name !== null) && yDomain.push(d.name))
        }
      });
      this.y.domain(yDomain);
    }
    
    else {
      // y domain
      if (this.secondYAxis) this.y1.domain(getYDomain(data.filter(d => d.secondYAxis))); 
      this.y.domain(getYDomain(data.filter(d => !d.secondYAxis)));

      // x domain
      let xDomain = [];
      data.forEach(d => {
        if (d.domain) {
          xDomain = xDomain.concat(d.domain)
        } else {
          d.data.forEach(dd => (dd.date !== null) && xDomain.push(dd.date))
        }
      });
      this.x.domain(xDomain);
    }
  };

  initial = data => {
    const { id, type, chartType, innerWidth, innerHeight, margin, selectedDate } = this;

    // clear old svg
    d3
      .select(ReactDOM.findDOMNode(this))
      .select('.' + type + "-svg")
      .remove();
    
    // clear old tooltip
    d3
      .select("body")
      .select("#tooltip_" + id)
      .remove()

    // create new tooltip  
    this.tooltip = d3
      .select("body")
      .append("div")
      .attr("id", "tooltip_" + id)
      .attr("class", "tooltip")
      .style("opacity", 0);

    this.line = (secondYAxis) => d3
      .line()
      .defined(this.defined)
      .x(d => this.x(d.date) + this.x.bandwidth() / 2)
      .y(d => secondYAxis ? this.y1(d.value) || 0 : this.y(d.value) || 0)
      .curve(d3.curveMonotoneX);

    // add svg
    this.svg = d3
      .select(ReactDOM.findDOMNode(this))
      .append("svg")
      .attr("class", type + "-svg")
      .attr("width", innerWidth + margin.left + margin.right)
      .attr("height", innerHeight + margin.top + margin.bottom + (this.props.full_screen ? 10 : 0));
    
    if (selectedDate) {
      this.svg.style('cursor', 'pointer');
      this.svg.on('click', () => {
        const eachBand = this.x.step();
        const domain = this.x.domain();
        let index = Math.floor(((d3.mouse(d3.event.target)[0] - (d3.event.target.tagName === "svg" ? margin.left : 0) - this.x(domain[0])) / eachBand));
        index = index < 0 ? 0 : (index > domain.length - 1 ? domain.length - 1 : index);
        const date = domain[index];
        this.props.changeSelectedDate(this.id, date);
      })
    }  

    // add g
    this.g = this.svg
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // add legend
    this.svg
      .append("g")
      .attr("class", "legend");

    // add axes
    this.g.append("g").attr("class", "x-axis");
    this.g.append("g").attr("class", "y-axis");
    if (this.secondYAxis) this.g.append("g").attr("class", "y-axis y1-axis").attr("transform", "translate( " + innerWidth + ", 0 )");
    this.g.append("g").attr("class", "zero-line");
    // this.updateAxes(data);

    // add bar chart
    this.g.append("g").attr("class", "barChart");

    // add stacked bar chart
    this.g.append("g").attr("class", "stackChart");

    // add multiple line charts
    this.g
      .append("g")
      .attr("class", "lines")
      .attr("fill", "none");

    // check if data is empty  
    if (this.renderNoData(data)) return;  

    if (type === "bar-line") {
      this.prepareData(data);
      this.redrawBarChart(data.filter(d => d.type === "bar"));
      this.redrawLineChart(data.filter(d => d.type === "line"));
    } else if (type === "multiline") {
      this.prepareData(data);
      this.redrawLineChart(data.filter(d => d.type === "line"));
    } else if (type === "stacked") {
      const layers = this.getStackedData(data);
      this.prepareData(layers);
      this.redrawStackChart(layers);
    } else if (type === "stacked-line") {
      const layers = this.getStackedData(data.filter(d => d.type === "bar"));
      this.prepareData(layers, data);
      this.redrawStackChart(layers);
      this.redrawLineChart(data.filter(d => d.type === "line"));
    } else if (type === "horizontal-bar") {
      this.prepareData(data);
      this.redrawHorizontalBarChart(data);
    }

    // update axes
    this.updateAxes();

    // update legend
    if (type === 'horizontal-bar') {
      this.updateLegend(data[0].legend.map((l, i) => ({ type: 'bar', name: l, color: data[0].colors[i]})));
    } else {
      this.updateLegend(data);
    }

  };

  updateLegend = data => {
    const { type, margin, innerHeight, innerWidth, axisFontSize } = this;
    const { color_codes } = this.props;

    this.svg
      .select(".legend")
      .selectAll(".legend-item")
      .remove();

    const _legend = this.svg.select(".legend");

    const legend = _legend
      .selectAll(".legend-item")
      .data((data.length === 1 && data[0].singleLegendMode) 
        ? data[0].data.map(d => ({
          name: d.date,
          type: data[0].type,
          color: d.color
        })) 
        : data)
      .enter()
      .append("g")
      .attr("class", "legend-item");

    let xOffset = 0, yOffset = 0, xPadding = 25, yPadding = 12;

    legend.each((f, i) => {
      const node = legend.nodes()[i];
      const legendItem = d3.select(node);

      legendItem.attr("transform", `translate(${xOffset},${yOffset})`);

      if (type === "bar-line") {
        if (f.type === "bar") {
          legendItem
            .append("rect")
            .attr("x1", 0)
            .attr("y1", 0)
            .attr("width", 30)
            .attr("height", 7)
            .attr("fill", color_codes[f.name.split(" - ")[0]] || f.color || colors[i]);
        } else if (f.type === "line") {
          legendItem
            .append("line")
            .attr("x1", 0)
            .attr("y1", 4)
            .attr("x2", 30)
            .attr("y2", 4)
            .attr("stroke", color_codes[f.name.split(" - ")[0]] || (f.styles && f.styles.color) || colors[i])
            .attr("stroke-width", (f.styles && f.styles.strokeWidth) || 3)
            .attr("stroke-dasharray", (f.styles && f.styles.strokeDasharray) || 0);

          if (f.dots) {
            legendItem
              .append("circle")
              .attr("fill", "#fff")
              .attr("stroke", color_codes[f.name.split(" - ")[0]] || (f.styles && f.styles.color) || colors[i])
              .attr("class", "dot")
              .attr("cx", 15)
              .attr("cy", 4)
              .attr("r", 3);
          }
        }

        legendItem
          .append("text")
          .attr("x", 35)
          .attr("y", 6.5)
          .text(f.name)
          .style("font-size", axisFontSize);

      } else {
        if (f.type === "line") {
          legendItem
            .append("line")
            .attr("x1", 0)
            .attr("y1", 0)
            .attr("x2", 30)
            .attr("y2", 0)
            .attr("fill", "none")
            .attr("stroke", color_codes[f.name.split(" - ")[0]] || (f.styles && f.styles.color) || colors[i])
            .attr("stroke-width", 3);

          if (f.dots) {
            legendItem
              .append("circle")
              .attr("fill", "#fff")
              .attr("stroke", color_codes[f.name.split(" - ")[0]] || (f.styles && f.styles.color) || colors[i])
              .attr("class", "dot")
              .attr("cx", 15)
              .attr("cy", 0)
              .attr("r", 3);
          }
        } else if (f.type === "bar") {
          legendItem
            .append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", 7)
            .attr("height", 7)
            .style("fill", color_codes[f.name.split(" - ")[0]] || f.color || colors[i]);
        }
        legendItem
          .append("text")
          .attr("x", f.type === "line" ? 35 : 15)
          .attr("y", f.type === "line" ? 3 : 6.5)
          .text(f.name)
          .style("font-size", axisFontSize);
      }

      const rect = node.getBBox();
      if (xOffset + rect.width + xPadding > innerWidth - 5) {
        xOffset = 0;
        yOffset += yPadding;
      } else {
        xOffset += rect.width + xPadding;
      }
    });

    const size = _legend.node().getBBox();
    _legend.attr("transform", `translate(${margin.left + (innerWidth - size.width) / 2},${margin.top + innerHeight + (this.props.full_screen ? 55 : 45)})`);
  };

  updateAxes = () => {
    const { type, chartType, selectedDate, id, axisFontSize } = this;
    if (selectedDate) {
      this.g.selectAll('.selected-date').remove();
      this.g.append('line')
        .attr('class', 'selected-date')
        .attr('x1', (this.x(selectedDate) || 0) + this.x.bandwidth() / 2)
        .attr('y1', 0)
        .attr('x2', (this.x(selectedDate) || 0) + this.x.bandwidth() / 2)
        .attr('y2', this.innerHeight)
        .style('stroke', 'darkgray')
        .style('stroke-dasharray', 3)
        .style('stroke-width', '2px')
    }

    if (type === 'horizontal-bar') {
      this.svg
      .select(".x-axis")
      .attr("transform", "translate(0," + this.innerHeight + ")")
      .call(d3.axisBottom(this.x))

      // Add the Y Axis
      this.svg.select(".y-axis").call(
        d3
          .axisLeft(this.y)
      ).selectAll("text")
      .style("font-size", axisFontSize);
    } else {
      if (chartType === 'sales') {
        if (["NG_MG_10_SISO", "EOG"].includes(id)) {
          this.svg
          .select(".x-axis")
          .attr("transform", "translate(0," + this.innerHeight + ")")
          .call(d3.axisBottom(this.x))
          .selectAll("text")
          .style("text-anchor", "middle")
          .style("font-size", axisFontSize)
        } else {
          this.svg
          .select(".x-axis")
          .attr("transform", "translate(0," + this.innerHeight + ")")
          .call(d3.axisBottom(this.x).tickFormat(d3.utcFormat("%b %Y")).tickValues(tickValues(this.x.domain())))
          .selectAll("text")
          .style("text-anchor", "end")
          .style("font-size", axisFontSize)
          .attr("dx", "-.55em")
          .attr("dy", ".45em")
          .attr("transform", "rotate(-35)");
        }
      } else if (chartType === 'launch') {
        this.svg
          .select(".x-axis")
          .attr("transform", "translate(0," + this.innerHeight + ")")
          .call(d3.axisBottom(this.x))
          .selectAll("text")
          .style("font-size", axisFontSize)
      } else if (chartType === "finance") {
        this.svg
          .select(".x-axis")
          .attr("transform", "translate(0," + this.innerHeight + ")")
          .call(d3.axisBottom(this.x))
          .selectAll("text")
          .style("text-anchor", "middle")
          .style("font-size", axisFontSize)
      } else if (["SoD_AT", "SoD_Prev_AT"].includes(id)) {
        this.svg
          .select(".x-axis")
          .attr("transform", "translate(0," + this.innerHeight + ")")
          .call(d3.axisBottom(this.x))//.tickValues(this.props.data[0].data.map(d => d.date).filter((d, i) => i % 3 === 0)))
          .selectAll("text")
          .style("text-anchor", "end")
          .style("font-size", axisFontSize)
          .attr("dx", "-.55em")
          .attr("dy", ".45em")
          .attr("transform", "rotate(-35)");
      } else if (chartType === 'wk_sales') {
        this.svg
          .select(".x-axis")
          .attr("transform", "translate(0," + this.innerHeight + ")")
          .call(d3.axisBottom(this.x).tickFormat(d => d3.utcFormat("%d/%m/%Y")(d3.utcParse("%Y W%W")(d))).tickValues(tickValues(this.x.domain())))
          .selectAll("text")
          .style("text-anchor", "end")
          .style("font-size", axisFontSize)
          .attr("dx", "-.55em")
          .attr("dy", ".45em")
          .attr("transform", "rotate(-35)");
      }
      
      // Add the Y Axis
      this.svg.select(".y-axis").call(
        d3
          .axisLeft(this.y)
          // .ticks(10)
          .tickFormat(d => selectFormat(this.format, d))
      ).selectAll("text")
      .style("font-size", axisFontSize);

      if (this.secondYAxis) {
        this.svg.select(".y1-axis").call(
          d3
            .axisRight(this.y1)
            // .ticks(10)
            .tickFormat(d => selectFormat(this.secondYAxisFormat, d))
        ).selectAll("text")
          .style("font-size", axisFontSize);
      }
  
      if (type === "bar-line" || type === "stacked" || type === "stacked-line" || type === "multiline") {
        const zeroline = this.svg.select(".zero-line")
        if (type === "bar-line" || type === "stacked" || type === "stacked-line" || id === "DMS") { // + delta market share 
          if (id !== "DMS") zeroline.raise();
          zeroline.attr("transform", "translate(0," +  (this.y(0) || 0) + ")")
            .call(d3.axisBottom(this.center).ticks(0));
        } else {
          zeroline.attr("transform", "translate(0," +  this.innerHeight + ")")
            .call(d3.axisBottom(this.center).ticks(0));
        }
      }
    }
  };

  redrawLineChart = data => {
    const { chartType, labelFontSize, showLabels } = this;
    const { color_codes } = this.props;

    this.g
      .select(".lines")
      .selectAll(".line-g")
      .remove();

    const lines = this.g
      .select(".lines")
      .selectAll(".line-g")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "line-g");

    lines.each((d, i) => {
      const lineG = d3.select(lines.nodes()[i]);

      const lineData = d.data.filter(this.defined);//.filter(d => !isNaN(d.value) && d.value !== null)
      if (!lineData.length) return;

      // lines
      lineG
        .append("path")
        .attr("class", "line")
        .attr("d", this.line(d.secondYAxis)(lineData))
        .style("stroke", color_codes[d.name.split(" - ")[0]] || (d.styles && d.styles.color) || colors[i])
        .style(
          "stroke-width",
          (d.styles && d.styles.strokeWidth) || (i === 0 ? "3.5px" : "2.5px")
        )
        .style(
          "stroke-dasharray",
          (d.styles && d.styles.strokeDasharray) || null
        );

      //text
      lineG
        .append("text")
        .attr("transform", d => {
          return (
            "translate(" +
            (this.x(lineData[lineData.length - 1].date) + this.x.bandwidth() / 2) +
            "," +
            (d.secondYAxis ? this.y1(lineData[lineData.length - 1].value) || 0 : this.y(lineData[lineData.length - 1].value) || 0) +
            ")"
          );
        })
        .attr("dx", ".9em")
        .attr("dy", ".35em")
        .attr("text-anchor", "start")
        .style("fill", color_codes[d.name.split(" - ")[0]] || (d.styles && d.styles.color) || colors[i])
        .style("font-size", labelFontSize)
        .text(_ => selectFormat(d.secondYAxis ? this.secondYAxisFormat : this.format, lineData[lineData.length - 1].value));

      // dots
      if (d.dots) {
        const tooltip = this.tooltip;
        const format = this.format;
        const secondYAxisFormat = this.secondYAxisFormat;

        lineG
          .selectAll(".dot")
          .data(lineData.filter(this.defined))
          .enter()
          .append("circle")
          .attr("class", "dot")
          .attr("cx", (dd, i) => this.x(dd.date) + this.x.bandwidth() / 2)
          .attr("cy", dd => d.secondYAxis ? this.y1(dd.value) || 0 : this.y(dd.value) || 0)
          .attr("r", i === 0 ? 4 : 3)
          .style("stroke", color_codes[d.name.split(" - ")[0]] || (d.styles && d.styles.color) || colors[i])
          .attr("fill", "#fff")
          .on("mouseover", function(dd) {
            d3.select(this)
              .style("fill", color_codes[d.name.split(" - ")[0]] || (d.styles && d.styles.color) || colors[i])
              .attr("r", i === 0 ? 5 : 4);

            const tpl =
              `<ul><li><div class="square" style="background-color: ${color_codes[d.name.split(" - ")[0]] || (d.styles &&
                d.styles.color) ||
                colors[i]}"></div>${d.name}: ${selectFormat(
                  d.secondYAxis ? secondYAxisFormat : format,
                dd.value
              )}</li>` +
              `<li>${chartType === 'launch' ? dd.date : "Date: " + (typeof dd.date === "string" ? dd.date : d3.utcFormat("%b %Y")(dd.date))}</li><ul>`;

            tooltip
              .transition()
              .duration(200)
              .style("opacity", 0.9);
            tooltip
              .html(tpl)
              .style("left", `${d3.event.pageX + 8}px`)
              .style("top", `${d3.event.pageY - 48}px`);
          })
          .on("mouseout", function(dd) {
            d3.select(this)
              .style("fill", "#fff")
              .attr("r", i === 0 ? 4 : 3);

            tooltip
              .transition()
              .duration(500)
              .style("opacity", 0);
          });
      }

      // labels
      if (showLabels) {
        lineG
          .selectAll(".dot-label")
          .data(lineData.slice(0, -1).filter(this.defined))
          .enter()
          .append("text")
          .attr("class", "dot-label")
          .attr("x", (dd, i) => this.x(dd.date) + this.x.bandwidth() / 2)
          .attr("y", dd => d.secondYAxis ? this.y1(dd.value) : this.y(dd.value))
          .attr("dx", ".1rem")
          .attr("dy", "-.5rem")
          .attr("fill", color_codes[d.name.split(" - ")[0]] || (d.styles && d.styles.color) || colors[i])
          .style("text-anchor", "middle")
          .style("font-size", labelFontSize)
          .text(d => selectFormat(d.secondYAxis ? this.secondYAxisFormat : this.format, d.value));
      }
    });
    d3.select(lines.nodes()[0]).raise();
  };

  redrawBarChart = data => {
    const { innerWidth, height, margin, labelFontSize } = this;
    const { color_codes } = this.props;

    this.g
      .select(".barChart")
      .selectAll(".bar")
      .remove();
    this.g
      .select(".barChart")
      .selectAll("text")
      .remove();

    if (!data) return;
    if (!data.length) return;

    const tooltip = this.tooltip;
    const format = this.format;

    if (data.length > 1) {
      const barData = data[0].data.map((d, i) => {
        const _d = {
          date: d.date
        };
        data.forEach(item => _d[item.name] = item.data[i] !== undefined ? item.data[i].value : null);
        return _d;
      });
      const keys = data.map(d => d.name);
      const groupKey = "date";

      const x0 = d3.scaleBand()
        .domain(barData.map(d => d[groupKey]))
        .rangeRound([0, innerWidth])
        .padding(0.2);

      const x1 = d3.scaleBand()
        .domain(keys)
        .rangeRound([0, x0.bandwidth()-1])
        .padding(0.1)

      this.g
        .select(".barChart")
        .selectAll("g")
        .data(barData)
        .join("g")
          .attr("transform", d => `translate(${x0(d[groupKey])},0)`)
        .selectAll("rect")
        .data(d => keys.map(key => ({key, value: d[key], date: d.date, color: data.find(q => q.name === key).color})))
        .join("rect")
          .attr("class", "bar")
          .attr("x", d => x1(d.key))
          .attr("y", d => d.value < 0 ? this.y(0) + 0.5 : this.y(d.value) + 0.5)
          .attr("width", x1.bandwidth())
          .attr("height", d => 
            d.value < 0 ? this.y(d.value) - this.y(0) : this.y(0) - this.y(d.value)
          )
          .attr("fill", d => color_codes[d.name] || d.color)
          .on("mouseover", function(d) {
            const tpl =
              `<ul><li><div class="square" style="background-color: ${
                color_codes[d.name] || d.color
              }"></div>${d.key}: ${selectFormat(format, d.value)}</li>` +
              `<li>Date: ${(typeof d.date === "string" ? d.date : d3.utcFormat("%b %Y")(d.date))}</li><ul>`;
    
            tooltip
              .transition()
              .duration(200)
              .style("opacity", 0.9);
            tooltip
              .html(tpl)
              .style("left", `${d3.event.pageX + 8}px`)
              .style("top", `${d3.event.pageY - 48}px`);
          })
          .on("mouseout", function(d) {
            tooltip
              .transition()
              .duration(500)
              .style("opacity", 0);
          });

    } else {
      const barData = data[0].data.filter(d => !isNaN(d.value) && d.value !== null);

      const barChart = this.g
        .select(".barChart")
        .selectAll(".bar")
        .data(barData);
  
      const color = (d, i) => {
        return d.value < 0 ? "#ffafb1" : (color_codes[d.name] || d.color || data[0].color || "#caf299");
      }        
  
      const lastObj = barData[barData.length - 1] || {};
  
      // bar chart
      barChart
        .enter()
        .append("rect")
        .attr("class", "bar")
        .style("fill", color)
        .attr("x", d => this.x(d.date))
        .attr("y", d => (d.value < 0 ? this.y(0) + 0.5 : (this.y(d.value) || 0) + 0.5))
        .attr("width", this.x.bandwidth())
        .attr("height", d =>
          d.value < 0 ? (this.y(d.value) - this.y(0) || 0) : (this.y(0) - this.y(d.value) || 0)
        )
        .on("mouseover", function(d) {
          const tpl =
            `<ul><li><div class="square" style="background-color: ${color(
              d
            )}"></div>${(typeof d.date === "string" ? d.date : d3.utcFormat("%b %Y")(d.date))}: ${selectFormat(data[0].format || format, d.value)}</li><ul>`;
  
          tooltip
            .transition()
            .duration(200)
            .style("opacity", 0.9);
          tooltip
            .html(tpl)
            .style("left", `${d3.event.pageX + 8}px`)
            .style("top", `${d3.event.pageY - 48}px`);
        })
        .on("mouseout", function(d) {
          tooltip
            .transition()
            .duration(500)
            .style("opacity", 0);
        });
  
      if (this.showLabels) {
        this.g
          .select(".barChart")
          .selectAll('.label')
          .data(barData)
          .enter()
          .append("text")
          .attr('class', 'label')
          .text(d => selectFormat(data[0].format || format, d.value))
          .attr(
            "transform", d =>
            "translate(" +
              ((this.x(d.date) + this.x.bandwidth() / 2) || 0) +
              "," +
              (this.y(d.value) || 0) +
              ")"
          )
          .style("font-size", labelFontSize)
          .style("text-anchor", "middle")
          .attr("dy", d => d.value < 0 ? ".8rem" : "-.3rem");
      } else {
        this.g
          .select(".barChart")
          .append("text")
          .text(selectFormat(data[0].format || format, lastObj.value))
          .attr(
            "transform",
            "translate(" +
              ((this.x(lastObj.date) + this.x.bandwidth() / 2) || 0) +
              "," +
              (this.y(lastObj.value) || 0) +
              ")rotate(-90)"
          )
          .style("font-size", labelFontSize)
          .attr("dx", lastObj.value >= 0 ? "10px" : "-45px")
          .attr("dy", "3px");    
      }
      
      barChart.exit().remove();
    }
  };

  redrawHorizontalBarChart = _data => {
    const { labelFontSize } = this;
    const data = _data[0].data;

    this.g
      .select(".barChart")
      .selectAll(".bar-item")
      .remove();

    if (!data) return;
    if (!data.length) return;

    const tooltip = this.tooltip;
    const format = this.format;

    const bars = this.g
      .select(".barChart")
      .selectAll(".bar-item")
      .data(data)
      .enter();
    
    const barItem = bars.append('g')
      .attr('class', 'bar-item')
      barItem.append("rect")
        .attr("y", d => this.y(d.name))
        .attr("x", d => 0.5)
        .attr("height", this.y.bandwidth())
        .attr("width", d => this.x(d.value) + 0.5)
        .attr("fill", _data[0].colors[0])
        .on("mouseover", function(d) {
          const tpl =
            `<ul><li>${d.name}</li><li><div class="square" style="background-color: ${
              _data[0].colors[0]
            }"></div>${_data[0].legend[0]}: ${selectFormat(format, d.value)}</li>` +
            `<li><div class="square" style="background-color: ${
              _data[0].colors[1]
            }"></div>${_data[0].legend[1]}: ${selectFormat(format, d.innerValue)}</li></ul>`;
  
          tooltip
            .transition()
            .duration(200)
            .style("opacity", 0.9);
          tooltip
            .html(tpl)
            .style("left", `${d3.event.pageX + 8}px`)
            .style("top", `${d3.event.pageY - 48}px`);
        })
        .on("mouseout", function(d) {
          tooltip
            .transition()
            .duration(500)
            .style("opacity", 0);
        })
      barItem.append("rect")
        .attr("y", d => this.y(d.name) + this.y.bandwidth()/6)
        .attr("x", d => 0.5)
        .attr("height", this.y.bandwidth() - this.y.bandwidth()/3)
        .attr("width", d => this.x(d.innerValue) + 0.5)
        .attr("fill", d => _data[0].colors[1])
        .style('pointer-events', 'none')
      barItem.append("text")
        .attr("y", d => this.y(d.name) + this.y.bandwidth()/2 + 4)
        .attr("x", d => this.x(d.value) + 4.5)
        .text(d => selectFormat(format, d.value))
        .style('pointer-events', 'none')  
        .style('font-size', labelFontSize)
      barItem.append("text")
        .attr("y", d => this.y(d.name) + this.y.bandwidth()/2 + 4)
        .attr("x", d => this.x(d.innerValue) + 4.5)
        .text(d => selectFormat(format, d.innerValue))
        .style('pointer-events', 'none')  
        .style('font-size', labelFontSize)  
  };

  redrawStackChart = data => {
    const { id, format, tooltip, showLabels } = this;
    const { color_codes } = this.props;

    this.g
      .select(".stackChart")
      .selectAll(".layer")
      .remove();

    const layers = this.g
      .select(".stackChart")
      .selectAll("layer")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "layer");

    layers.each((d, i) => {
      const layer = d3.select(layers.nodes()[i]);

      layer
        .selectAll("rect")
        .data(_d => _d)
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", dd => this.x(dd.data.date))
        .attr("y", dd => this.y(dd[1]))
        .attr("width", this.x.bandwidth())
        .attr("height", dd => this.y(dd[0]) - this.y(dd[1]))
        .style("fill", color_codes[d.key] || d.color || colors[i])
        .on("mouseover", function(dd, k) {
          const tpl =
            `<ul><li><div class="square" style="background-color: ${
              color_codes[d.key] || d.color || colors[i]
            }"></div>${d.key}: ${selectFormat(format, dd[1] - dd[0])}</li>` +
            `<li>${["SoD_AT", "SoD_Prev_AT"].includes(id) ? 'Detail' : 'Date'}: ${typeof dd.data.date === "string" ? dd.data.date : d3.utcFormat("%b %Y")(dd.data.date)}</li><ul>`;

          tooltip
            .transition()
            .duration(200)
            .style("opacity", 0.9);
          tooltip
            .html(tpl)
            .style("left", `${d3.event.pageX + 8}px`)
            .style("top", `${d3.event.pageY - 48}px`);
        })
        .on("mouseout", function(d) {
          tooltip
            .transition()
            .duration(500)
            .style("opacity", 0);
        });
    });

    if (showLabels) {
      layers.each((d, i) => {
        const layer = d3.select(layers.nodes()[i]);
  
        layer
          .selectAll(".bar-label")
          .data(_d => _d.filter(dd => Math.abs(dd[1] - dd[0]) > 2))
          .enter()
          .append("text")
          .attr("class", "bar-label")
          .attr("x", dd => this.x(dd.data.date) + this.x.bandwidth() / 2)
          .attr("y", dd => (this.y(dd[0]) + this.y(dd[1])) / 2 )
          .attr("dy", "4px")
          .style("text-anchor", "middle")
          .style("font-size", "12px")
          .text(dd => selectFormat(format, dd[1] - dd[0]))
      });  
    }
    
    layers.exit().remove();
  };

  renderNoData = data => {
    this.svg.selectAll('.no_data_overlay').remove();

    const dataLength = d3.sum(data.map(d => d.data.length));
  
    if (dataLength < 1) {
      const no_data = this.svg.append('g')
        .attr('class', 'no_data_overlay')
        
      no_data.append('rect')
        .attr('width', this.width)
        .attr('height', this.height)
        .attr('fill', "#fff")
      no_data.append('text')
        .attr('x', this.width / 2)
        .attr('y', this.height / 2)
        .attr('text-anchor', 'middle')
        .text('No Data')  
      
      return true;
    }

    return false;
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillReceiveProps(next) {
    if (next.data !== this.props.data || next.full_screen !== this.props.full_screen || next.isShowRP !== this.props.isShowRP) {
      const { data } = next;
      const { type } = this;

      if (next.selectedDate !== this.selectedDate) {
        this.selectedDate = next.selectedDate;
      }

      if (this.renderNoData(data)) return;

      const refresh = () => {
        if (type === "bar-line") {
          this.prepareData(data);
          this.redrawBarChart(data.filter(d => d.type === "bar"));
          this.redrawLineChart(data.filter(d => d.type === "line"));
        } else if (type === "multiline") {
          this.prepareData(data);
          this.redrawLineChart(data.filter(d => d.type === "line"));
        } else if (type === "stacked") {
          const layers = this.getStackedData(data);
          this.prepareData(layers);
          this.redrawStackChart(layers);
        } else if (type === "stacked-line") {
          const layers = this.getStackedData(data.filter(d => d.type === "bar"));
          this.prepareData(layers, data);
          this.redrawStackChart(layers);
          this.redrawLineChart(data.filter(d => d.type === "line"));
        } else if (type === "horizontal-bar") {
          this.prepareData(data);
          this.redrawHorizontalBarChart(data);
        }

        // update axes
        this.updateAxes();

        // update legend
        if (type === "horizontal-bar") {
          this.updateLegend(data[0].legend.map((l, i) => ({ type: "bar", name: l, color: data[0].colors[i] })));
        } else {
          this.updateLegend(data);
        }
      };

      if (next.full_screen !== this.props.full_screen) {
        d3.select(this.refs[`chart_${this.props.id}`]).style("opacity", 0.0001);
        setTimeout(() => {
          this.updateDimensions();
          refresh();
          d3.select(this.refs[`chart_${this.props.id}`]).style("opacity", 1);
        }, 10);
      } else if (next.isShowRP !== this.props.isShowRP) {
        setTimeout(() => {
          this.updateDimensions();
          refresh();
        }, 10);
      } else {
        setTimeout(() => {
          if (next.format !== this.props.format) {
            this.format = next.format;
            this.yDomain = next.yDomain;
          }
          refresh();
        }, 200);
      }
    }
  }

  render() {
    return (
      <div ref={`chart_${this.props.id}`} className="multi-chart" id={this.props.id}>
        {this.props.children}
      </div>
    );
  }
}

export default Chart;
