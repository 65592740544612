const commonAction = (type) => (payload) => ({ type, payload });
const action = (type) => (config_id, payload) => ({ type, payload, config_id });

// LOGIN
export const LOGIN = 'LOGIN';
export const actionLogin = commonAction(LOGIN);

// LOGOUT
export const LOGOUT = 'LOGOUT';
export const actionLogout = commonAction(LOGOUT);

// INIT
export const INIT_LOADING = 'INIT_LOADING';
export const actionInitLoading = commonAction(INIT_LOADING);

export const LOAD_DATA = 'LOAD_DATA';
export const actionLoadData = commonAction(LOAD_DATA);
export const DID_LOAD_DATA = 'DID_LOAD_DATA';
export const actionDidLoadData = commonAction(DID_LOAD_DATA);

// CONFIG
export const CONFIG_LOADING = 'CONFIG_LOADING';
export const actionConfigLoading = action(CONFIG_LOADING);

export const SELECT_CONFIG = 'SELECT_CONFIG';
export const actionSelectConfig = action(SELECT_CONFIG);
export const DID_SELECT_CONFIG = 'DID_SELECT_CONFIG';
export const actionDidSelectConfig = action(DID_SELECT_CONFIG);

export const INIT_CONFIG = 'INIT_CONFIG';
export const actionInitConfig = action(INIT_CONFIG);
export const DID_INIT_CONFIG = 'DID_INIT_CONFIG';
export const actionDidInitConfig = action(DID_INIT_CONFIG);

export const SAVE_CONFIG = 'SAVE_CONFIG';
export const actionSaveConfig = action(SAVE_CONFIG);
export const DID_SAVE_CONFIG = 'DID_SAVE_CONFIG';
export const actionDidSaveConfig = action(DID_SAVE_CONFIG);

export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const actionUpdateConfig = ({ config_id, data }) => {
  return {
    type: UPDATE_CONFIG,
    payload: { config_id, data },
  };
};
export const DID_UPDATE_CONFIG = 'DID_UPDATE_CONFIG';
export const actionDidUpdateConfig = ({ config_id, data }) => {
  return {
    type: DID_UPDATE_CONFIG,
    payload: { config_id, newData: data },
  };
};

export const OPEN_SAVED_CONFIG = 'OPEN_SAVED_CONFIG';
export const actionOpenSavedConfig = ({ config_id }) => {
  return {
    type: OPEN_SAVED_CONFIG,
    payload: { config_id },
  };
};

export const DID_OPEN_SAVED_CONFIG = 'DID_OPEN_SAVED_CONFIG';
export const actionDidOpenSavedConfig = (config) => {
  return {
    type: DID_OPEN_SAVED_CONFIG,
    payload: config,
  };
};

export const ADD_NEW_WORKSHEET = 'ADD_NEW_WORKSHEET';
export const actionAddNewWorksheet = ({ data }) => {
  return {
    type: ADD_NEW_WORKSHEET,
    payload: { data },
  };
};

export const CLOSE_CONFIG = 'CLOSE_CONFIG';
export const actionCloseConfig = ({ action = 'close', config_id }) => {
  return {
    type: CLOSE_CONFIG,
    payload: { action, config_id },
  };
};

export const DID_CLOSE_CONFIG = 'DID_CLOSE_CONFIG';
export const actionDidCloseConfig = ({
  config_id,
  selectedTabIndex = null,
}) => {
  return {
    type: DID_CLOSE_CONFIG,
    payload: { config_id, selectedTabIndex },
  };
};

export const SELECT_TAB = 'SELECT_TAB';
export const actionSelectTab = (index) => {
  return {
    type: SELECT_TAB,
    payload: { index },
  };
};

export const GET_DATASOURCES = 'GET_DATASOURCES';
export const actionGetDatasources = () => ({
  type: GET_DATASOURCES,
});

export const DID_GET_DATASOURCES = 'DID_GET_DATASOURCES';
export const actionDidGetDatasources = (data) => ({
  type: DID_GET_DATASOURCES,
  payload: { data },
});

// QUERY
export const QUERY_LOADING = 'QUERY_LOADING';
export const actionQueryLoading = commonAction(QUERY_LOADING);

export const QUERY = 'QUERY';
export const actionQuery = action(QUERY);

export const DID_QUERY = 'DID_QUERY';
export const actionDidQuery = action(DID_QUERY);

export const SELECT_VIEW = 'SELECT_VIEW';
export const actionSelectView = (config_id, data, key, dim) => {
  return {
    type: SELECT_VIEW,
    payload: { data, key, dim },
    config_id,
  };
};
export const DID_SELECT_VIEW = 'DID_SELECT_VIEW';
export const actionDidSelectView = action(DID_SELECT_VIEW);

export const SELECT_TABLE_VIEW = 'SELECT_TABLE_VIEW';
export const actionSelectTableView = action(SELECT_TABLE_VIEW);
export const DID_SELECT_TABLE_VIEW = 'DID_SELECT_TABLE_VIEW';
export const actionDidSelectTableView = action(DID_SELECT_TABLE_VIEW);

export const SELECT_CHART_VIEW = 'SELECT_CHART_VIEW';
export const actionSelectChartView = action(SELECT_CHART_VIEW);

export const SELECT_CONTROL_TAB = 'SELECT_CONTROL_TAB';
export const actionSelectControlTab = action(SELECT_CONTROL_TAB);

export const SELECT = 'SELECT';
export const actionSelect = action(SELECT);

export const CHANGE_SORT = 'CHANGE_SORT';
export const actionChangeSort = (config_id, sortId, sortOrder) => {
  return {
    type: CHANGE_SORT,
    payload: { sortId, sortOrder },
    config_id,
  };
};

export const PLUS = 'PLUS';
export const actionPlus = action(PLUS);

export const MINUS = 'MINUS';
export const actionMinus = action(MINUS);

export const CHANGE_SELECTED_DATE = 'CHANGE_SELECTED_DATE';
export const actionChangeSelectedDate = (config_id, id, date) => {
  return {
    type: CHANGE_SELECTED_DATE,
    payload: { id, date },
    config_id,
  };
};

export const CHANGE_RADIO = 'CHANGE_RADIO';
export const actionChangeRadio = (config_id, i, ii, type) => {
  return {
    type: CHANGE_RADIO,
    payload: { i, ii, type },
    config_id,
  };
};
export const DID_CHANGE_RADIO = 'DID_CHANGE_RADIO';
export const actionDidChangeRadio = (config_id, i, ii, type) => {
  return {
    type: DID_CHANGE_RADIO,
    payload: { i, ii, type },
    config_id,
  };
};

export const CONTROLS = 'CONTROLS';
export const actionControl = (config_id, active, i, ii, iii, date) => {
  return {
    type: CONTROLS,
    payload: {
      active: !active,
      tab_index: i,
      group_index: ii,
      index: iii,
      date,
    },
    config_id,
  };
};
export const DID_CONTROLS = 'DID_CONTROLS';
export const actionDidControls = action(DID_CONTROLS);

export const CONTROL_RESET = 'CONTROL_RESET';
export const actionControlReset = (config_id, i, ii) => {
  return {
    type: CONTROL_RESET,
    payload: { tab_index: i, group_index: ii },
    config_id,
  };
};
export const DID_CONTROL_RESET = 'DID_CONTROL_RESET';
export const actionDidControlReset = action(DID_CONTROLS);

export const FIRST_HEADER_ICON = 'FIRST_HEADER_ICON';
export const actionFirstHeaderIcon = action(FIRST_HEADER_ICON);

export const LAST_HEADER_ICON = 'LAST_HEADER_ICON';
export const actionLastHeaderIcon = action(LAST_HEADER_ICON);

export const CHANGE_KPI = 'CHANGE_KPI';
export const actionChangeKPI = (config_id, data, type) => {
  return {
    type: CHANGE_KPI,
    payload: { data, type },
    config_id,
  };
};
export const DID_CHANGE_KPI = 'DID_CHANGE_KPI';
export const actionDidChangeKPI = (config_id, data, type) => {
  return {
    type: DID_CHANGE_KPI,
    payload: { data, type },
    config_id,
  };
};

export const CHANGE_CHART_CONTROL = 'CHANGE_CHART_CONTROL';
export const actionChangeChartControl = (config_id, name, type, value) => {
  return {
    type: CHANGE_CHART_CONTROL,
    payload: { name, type, value },
    config_id,
  };
};

export const SET_FS_INDEX = 'SET_FS_INDEX';
export const actionSetFsIndex = commonAction(SET_FS_INDEX);

export const SET_FS_TABLE = 'SET_FS_TABLE';
export const actionSetFsTable = commonAction(SET_FS_TABLE);

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const actionAddNotification = commonAction(ADD_NOTIFICATION);

export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const actionRemoveNotification = commonAction(REMOVE_NOTIFICATION);


