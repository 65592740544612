import React from "react";

import { getDataItemNew, getEndDate } from './../../store/reducers/config/helper'
import numeral from "numeral";

import "./Main.scss";
import BarChart from "./BarChart";

const cards = [
  {
    id: 0,
    name: "Sales",
    title: "Mkt Sales",
    format: 'number',
    data: [],
    baseColor: "#176dca"
  },
  {
    id: 1,
    name: "MS",
    title: "Market Share",
    format: 'percent',
    data: [],
    baseColor: "#176dca"
  },
  {
    id: 2,
    name: "MS vs PY",
    title: "MS Gain/Loss",
    format: 'pts',
    data: []
  },
  {
    id: 3,
    name: "Gt vs PY",
    title: "Growth vs PY",
    format: 'number',
    data: []
  },
  {
    id: 4,
    name: "Gt vs Mkt",
    title: "Growth vs MG",
    format: 'percent',
    data: []
  },
  {
    id: 5,
    name: "EI",
    title: "Evolution Index",
    format: 'number',
    data: []
  },

  {
    id: 6,
    name: "Act",
    title: "Actuals",
    format: 'number',
    data: [],
    baseColor: "#176dca"
  },
  {
    id: 7,
    name: "A vs B",
    title: "Act vs Bud",
    format: 'number',
    data: [],
    baseColor: "#176dca"
  },
  {
    id: 8,
    name: "%A vs B",
    title: "%Act vs Bud",
    format: 'percent',
    data: []
  },
  {
    id: 9,
    name: "A vs T",
    title: "Act vs Tgt",
    format: 'number',
    data: []
  },
  {
    id: 10,
    name: "%A vs T",
    title: "%Act vs Tgt",
    format: 'percent',
    data: []
  },
  {
    id: 11,
    name: "%Gt vs PY",
    title: "%Growth vs PY",
    format: 'percent',
    data: []
  },
  {
    id: 12,
    name: "%Gt vs Per-1",
    title: "%Growth vs P-1",
    format: 'percent',
    data: []
  }
];

// arrow direction and color
const selectArrow = (data) => {
  const MTH = data[0]
      , RQTR = data[1]
      , MAT = data[2];

  let obj = {
    color: "",
    rotate: ""
  };
  switch (true) {
    case (MTH < RQTR) && (RQTR < MAT):
      obj.color = "red";
      obj.rotate = "180deg";
      return obj;
    case (MTH > RQTR) && (RQTR < MAT):
      obj.color = "orange";
      obj.rotate = "135deg";
      return obj;
    case (MTH < RQTR) && (RQTR > MAT):
      obj.color = "orange";
      obj.rotate = "45deg";
      return obj;
    case (MTH > RQTR) && (RQTR > MAT):
      obj.color = "green";
      obj.rotate = "0";
      return obj;
    default:
      return "";
  }
};

const selectFormat = (f, d, m) => {
  const format = Math.abs(d) < 1000 ? numeral(d).format("0.[0]") : numeral(d).format("0.0a");
  switch (f) {
    case "pts":
      return numeral(100 * d).format("0.0") + "pts";
    case "percent":
      return numeral(d).format("0.0%");
    case "euro_number":
      return "€" + format.toUpperCase();
    default:
      return format.toUpperCase() + (m ? `(${m})` : "");
  }
};

export default class Tooltip extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      dimensions: {}
    }
  }

  componentDidMount(){
    this.setState({
      dimensions: {
        width: this.container.offsetWidth,
        height: this.container.offsetHeight,
      }
    }) 
  }

  render() {
    const { data, dims, selectedId, metrics, dateEnd, dateParse, view } = this.props;
    const { width, height } = this.state.dimensions;
    
    let selectedData, country, cat;
    if (view === 'executive') {
      [ , country, cat, ] = selectedId.split(':');
      selectedData = data.data.find(d => d.id === country);
      
      if (dims.includes("Category5")) {
        selectedData = selectedData ? selectedData.children.find(d => d.id === cat) : null;
      }
      selectedData = selectedData ? selectedData.children.find(d => d.id === selectedId) : [];
    } else {
      selectedData = getDataItemNew(data.data, selectedId);
    }

    let _metric = metrics[1].controls.find(d => d.active).name;
    
    // set up correct format if Euro metric is selected
    if (['EU', 'Euro', 'EUR', 'EU (MNF)', 'EU (PUB)'].includes(_metric === "Default" ? selectedData.default_metric : _metric)) {
      cards[0].format = 'euro_number';
      cards[5].format = 'euro_number';
    } else {
      cards[0].format = 'number';
      cards[0].defaultMetric = _metric === "Default" ? selectedData.default_metric : _metric;
      cards[5].format = 'number';
    }

    const selectedMetrics = 
      // market  
      metrics[2].controls
        .filter(d => d.active)
        .map(d => d.name)
        .concat(
          // finance
          metrics[9].controls
          .filter(d => d.active)
          .map(d => d.name));

    const selectedCards = cards.filter(d => selectedMetrics.includes(d.name))
    selectedCards.forEach(card => {
      card.data = metrics[3].controls.filter(d => d.active).map(d => ({
        name: d.name,
        value: selectedData ? selectedData[d.name + card.name] : 0,
        period: d.period
      }))
    })

    const count = Math.max(selectedCards.length > 5 ? 5 : selectedCards.length, 2);

    return (
      <div className="row m-tooltip">
        <div className="col col-12 tooltip-container">
          <div className="tooltip-header">
            <div className="h-word">
              Performance of { 
                selectedData ? selectedData.key : "-"
              } ({
                country ? country + " - " : ""
              }{ 
                metrics[6].controls[0].active 
                  ? getEndDate({ dateEnd, dateParse }, 'market', metrics[0].controls[1].active) 
                  : getEndDate({ dateEnd: selectedData.dateEnd ? { market: selectedData.dateEnd } : dateEnd, dateParse }, 'market', metrics[0].controls[1].active) 
              })
            </div>
          </div>
          <div className="tooltip-charts" ref={el => (this.container = el)}>
            {selectedCards.map((d, i) => {
              const arrow = d.name === "Sales" 
                ? selectArrow(d.data.map(_d => _d.value / _d.period))
                : selectArrow(d.data.map(_d => _d.value))
              
              return (
                <div
                  key={i}
                  className={`tooltip-charts-item uk-card uk-card-hover uk-card-default uk-card-body uk-width-1-${count}@m`}
                >
                  <div className="t-card-header">
                    <div className="c-h-title">{d.title}</div>
                  </div>
                  <div className="t-card-value">
                    <div className="c-value">{selectFormat(d.format, d.data[0].value, d.defaultMetric)}</div>
                  </div>
                  <div className="t-card-charts">
                    <BarChart 
                      data={d.name === "Sales" 
                        ? d.data.map(_d => ({name: _d.name, value: _d.value / _d.period, period: _d.period})) 
                        : d.data
                      } 
                      type={d.name}
                      format={d.format} 
                      width={width / count < 170 ? 170 : (width / count)} 
                      height={height - 64} 
                      baseColor={d.baseColor}
                    />
                  </div>

                  <div className="c-h-icon">
                    <span
                      uk-icon="icon: arrow-up; ratio: 2"
                      style={{
                        color: arrow.color,
                        transform: "rotate(" + arrow.rotate + ")"
                      }}
                    />
                  </div>
                </div>
              )})
            }
          </div>
        </div>
      </div>
    );
  }
}
