import React, { Fragment } from "react";
import { connect } from "react-redux";
import XLSX from "xlsx";
import _ from "lodash";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import { Box, Paper, Typography, withStyles } from '@material-ui/core';

import BarChartIcon from '@material-ui/icons/BarChart';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import GetAppIcon from "@material-ui/icons/GetApp";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import OfflineBoltOutlinedIcon from "@material-ui/icons/OfflineBoltOutlined";
import MarketIcon from "../../icons/material_icons/MarketIcon";
import PortfolioIcon from "../../icons/material_icons/PortfolioIcon";
import SettingsIcon from '@material-ui/icons/Settings';
import CloseIcon from '@material-ui/icons/Close';

import numeral from "numeral";

import { 
  actionSelect, 
  actionSelectView, 
  actionChangeSort,
  actionChangeKPI
} from "./../../store/actions";
import { actionShowModal, actionHideModal } from "./../Modals/actions";
import { ModalTypes } from "./../Modals/constants";
import { isCountryDim, getEndDate, getMetric, checkMillionBillion, formatXLSXCell } from "./../../store/reducers/config/helper";
import { isDoubleClick } from "./utils";

import Tooltip from "./Tooltip";

import { Table } from "rsuite";
// import update from "immutability-helper";

import SimpleTooltip from "../Other/SimpleTooltip"

import { colors } from "../../shared/theme/theme"

import "rsuite/dist/styles/rsuite-default.min.css";
// import "rsuite/styles/less/index.less";
import "./RsuiteTable.scss";

const ChartPaper = withStyles((theme) => ({
  root: {
    borderRadius: 0,
    position: "relative",
    "&:hover": {
      boxShadow: "0 3px 6px" + theme.palette.grey[500]
    },
  },
}))(Paper);

const ContextMenuPaper = withStyles((theme) => ({
  root: {    
    overflow: "auto",
    width: 150,

    "& .contextmenu-table-header": {
      color: colors.white,
      backgroundColor: colors.black,
      padding: 5,
      display: "flex",
    },
    "& .contextmenu-table-header span": {
      fontSize: ".7rem"
    },

    "& .react-contextmenu-item": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      padding: 8
    },  
    "& .react-contextmenu-item:hover": {
      backgroundColor: theme.palette.grey[200]
    },
  },
}))(Paper);

const { Column, HeaderCell, Cell } = Table;

const selectFormat = (f, d) => {
  const neg = d < 0 ? "negative" : "";
  const format = Math.abs(d) < 1000 ? numeral(d).format("0.[0]") : numeral(d).format("0.0a");

  switch (f) {
    case "pts":
      return d ? <span className={neg}>{numeral(100 * d).format("0.0")}pts</span> : <span>-</span>;
    case "percent":
      return d ? <span className={neg}>{numeral(d).format("0.0%")}</span> : <span>-</span>;
    default:
      return d ? <span className={neg}>{format.toUpperCase()}</span> : <span>-</span>;
  }
};

class RsuiteTable extends React.Component {
  constructor(props) {
    super(props);

    const expandedRowKeys = [];
    if (props.view === "executive") {
      props.data.data.forEach(d => {
        expandedRowKeys.push(d.id);
        if (d.children) d.children.forEach(dc => expandedRowKeys.push(dc.id))
      })
    }
      
    this.state = {
      expanded: {},
      activeDropdown: false,
      yCoord: null, // y coordinate dropdown
      selectedItem: null,

      nData: [],//data,
      expandedRowKeys,
      dataList: [],
      tableWidth: 1200,
      tableHeight: 600,
      tableHeaderHeight: 80,
      tableRowHeight: 40,
      scrollX: 0,
      scrollY: 0,

      cogDialogParentId: null,

      fs_height: 282
    };

    this.qwerty = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handlerDropdownLink = this.handlerDropdownLink.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentWillReceiveProps(props) {
    // setTimeout(() => {
    //   this.setState({
    //     fs_height: (this.qwerty !== null && this.qwerty.current) ? this.qwerty.current.getBoundingClientRect().height - 25 : 282
    //   })
    // }, 10);
    if ((props.view === "executive" && this.props.view !== props.view) ||
        (props.view === "executive" && JSON.stringify(props.data.data.map(d => d.id)) !== JSON.stringify(this.props.data.data.map(d => d.id)))) {
      const expandedRowKeys = [];
      props.data.data.forEach(d => {
        expandedRowKeys.push(d.id);
        if (d.children) d.children.forEach(dc => expandedRowKeys.push(dc.id))
      })
      this.setState({ expandedRowKeys });
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.activeDropdown) {
      this.hideDropdown()
    }
  }

  onRowClickHandler = (rowData, e) => {
    // console.log("rowData: ", rowData, e);
    this.props.actionSelect({ id: rowData.id });
  };

  handlerDropdown(e, rowInfo) {
    console.log(e)
    if (e.type === "contextmenu") {
      e.preventDefault();
      this.setState({
        activeDropdown: true,
        yCoord: e.pageY - 80,
        selectedItem: rowInfo
      });
    }
    if (e.type === "click") {
      e.preventDefault();
      this.setState({
        activeDropdown: true,
        yCoord: e.pageY - 95
      });
    }
  }

  hideDropdown() {
    this.setState({
      activeDropdown: false
    });
  }

  handlerDropdownLink(e, data) {
    const { view, selectedItem } = data;

    if (view && view !== this.props.view) {
      if (view === "portfolio" && this.props.view !== "executive") {
        if (selectedItem) {
          this.props.actionSelectView(
            view, 
            selectedItem.country_value || selectedItem.key, 
            selectedItem.country_dim || selectedItem.dim
          );  
        } else {
          this.props.actionSelectView(view);
        }
      } else if (view === "portfolio" && this.props.view === "executive") {
        if (selectedItem) {
          this.props.actionSelectView(view, selectedItem.id, selectedItem.default_metric);  
        } else {
          this.props.actionSelectView(view);
        }
      } else if (this.props.view === "executive") {
        if (selectedItem) {
          this.props.actionSelectView(view, selectedItem.id, selectedItem.default_metric);  
        } else {
          this.props.actionSelectView(view);
        }
      } else {
        if (selectedItem) {
          this.props.actionSelectView(view, selectedItem.key, selectedItem.dim);  
        } else {
          this.props.actionSelectView(view);
        }
      }
    }
    this.hideDropdown();
  }

  handleClickSort = (_sortId) => {
    const { sortId, sortOrder, actionChangeSort } = this.props;
    if (_sortId === sortId) {
      actionChangeSort(_sortId, sortOrder === "desc" ? "asc" : "desc")
    } else {
      actionChangeSort(_sortId, "desc")
    }
  }
  
  handleOpenModal = (e, id, kpi) => {
    e.stopPropagation();

    const { metrics } = this.props;
    if (kpi) {
      this.props.actionShowModal(ModalTypes.RsuiteTableModal, {
        headerText: 'Edit KPI',
        modalConfig: {
          modalType: 'Edit',
          datasource: this.props.datasource,
          metrics,
          sfeControls: this.props.sfeControls,
          handleCloseModal: this.handleCloseModal,
          modalData: {
            id: kpi.id,
            title: kpi.title,
            dataType: kpi.dataType,
            metric: kpi.metric,
            kpi: kpi.name,
            aggr: kpi.aggr ? kpi.aggr.name : null,
            customer_segment: kpi.customer_segment,
            customer_specialty: kpi.customer_specialty,
          },
        },
      });
    } 
    else {
      // select group with market dataType
      const kpiGroup = this.props.datasource.kpiGroups.find(d => d.dataType === 'market');

      this.props.actionShowModal(ModalTypes.RsuiteTableModal, {
        headerText: 'Add KPI',
        modalConfig: {
          modalType: 'Add',
          datasource: this.props.datasource,
          metrics,
          sfeControls: this.props.sfeControls,
          handleCloseModal: this.handleCloseModal,
          modalData: {
            id: null,
            title: kpiGroup.name,
            dataType: kpiGroup.dataType,
            metric: metrics[1].controls[0].name,
            kpi: metrics[2].controls[0].name,
            aggr: metrics[3].controls[0].name,
            group: kpiGroup.name,
          },
        },
      });
    }
    
  }
  
  handleCloseModal = (modalData, modalType) => {
    this.props.actionHideModal();

    if (modalType) {
      this.props.actionChangeKPI(modalData, modalType);
    }
  }

  downloadXLS = _data => {
    const { expandedRowKeys } = this.state;
    const { view, dims, activeTableView, kpis, datasource, metrics, dateParse } = this.props;
    const showDate = metrics[6].controls[1].active;
    
    let data = { data: [], total: _data.total };

    if (dims.length > 1) {
      // show data up to 3 levels
      _data.data.forEach(d => {
        // 1 level
        data.data.push(d);
        if (expandedRowKeys.includes(d.id)) {
          d.children.forEach(d1 => {
            // 2 level
            data.data.push(d1);
            if (expandedRowKeys.includes(d1.id)) {
              d1.children.forEach(d2 => {
                // 3 level
                data.data.push(d2);
                if (expandedRowKeys.includes(d2.id)) {
                  // 4 level
                  Array.prototype.push.apply(data.data, d2.children);
                }
              });
            }
          });
        }
      })
    } else {
      data = _data;
    }

    let firstRowAfterHeader = {}; // first row after header rows
    let arrNames = [{ name: "key", fm: "", original_name: "" }]; // array names of column and format value
    let mainArr = []; // main data from table(name, value, ...)
    let arrJsonToSheet = [""]; //array aggr titles
    let arrMarketFinance = [""]; //array market/finance/forecast/sit titles
    let numberOfMergeColumns = [0, 0, 0, 0]; // number columns to merge
    let numberOfAggrTitles = 0; // number of aggr titles

    // Trend table
    if (activeTableView === 0) {
      this.props.metrics[3].controls
      .filter(aggr => aggr.active)
      .forEach(aggr => {
        arrJsonToSheet.push(aggr.title);
        numberOfAggrTitles += 1;

        // finance
        this.props.metrics[9].controls
          .filter(calc => calc.active)
          .forEach((calc, i) => {
            numberOfMergeColumns[0] = i + 1;
            arrJsonToSheet.push("");
            arrMarketFinance.push(i > 0 ? "" : "Finance");
            arrNames.push({
              name: aggr.name + calc.name,
              fm: calc.format,
              original_name: calc.name
            });
          });
        
        // market data
        this.props.metrics[2].controls
          .filter(calc => calc.active)
          .forEach((calc, i) => {
            numberOfMergeColumns[1] = i + 1;
            arrJsonToSheet.push("");
            arrMarketFinance.push(i > 0 ? "" : "Market");
            arrNames.push({
              name: aggr.name + calc.name,
              fm: calc.format,
              original_name: calc.name
            });
          });

        // forecast
        this.props.metrics[10].controls
          .filter(calc => calc.active)
          .forEach((calc, i) => {
            numberOfMergeColumns[2] = i + 1;
            arrJsonToSheet.push("");
            arrMarketFinance.push(i > 0 ? "" : "Forecast");
            arrNames.push({
              name: aggr.name + calc.name,
              fm: calc.format,
              original_name: calc.name
            });
          });

        // sit
        this.props.metrics[12].controls
          .filter(calc => calc.active)
          .forEach((calc, i) => {
            numberOfMergeColumns[3] = i + 1;
            arrJsonToSheet.push("");
            arrMarketFinance.push(i > 0 ? "" : "SIT");
            arrNames.push({
              name: aggr.name + calc.name,
              fm: calc.format,
              original_name: calc.name
            });
          });  

        arrJsonToSheet.splice(-1, 1);
      });
    } 
    
    // Customized table
    else {

      ;['Title'].forEach(t => {
        arrJsonToSheet.push(t);
        numberOfAggrTitles += 1;

        datasource.kpiGroups
          .forEach((group, i) => {
            const filteredKpis = kpis.filter(d => d.group === group.name);

            numberOfMergeColumns[i] = filteredKpis.length;

            filteredKpis.forEach((kpi, j) => {
              arrJsonToSheet.push("");
              arrMarketFinance.push(j > 0 ? "" : group.name);
              arrNames.push({
                name: kpi.id,
                fm: kpi.format,
                original_name: kpi.name + ' - ' + kpi.metric + ' - ' + (kpi.aggr ? kpi.aggr.name : '')
              });
            })
          });
        
        arrJsonToSheet.splice(-1, 1);
      });
    }
    
    // make main data
    data.data.forEach(f => {
      let obj = {};
      arrNames.forEach((d, i) => {
        if (i === 0) {
          const dimArr = f.id.split(':');
          if (dimArr.length > 1) {
            let str = '';
            dimArr.forEach(_ => str += '  ');
            obj[d.name] = str + f[d.name];
            if (f.default_metric) obj[d.name] += ' (' + getMetric(f.default_metric) + ')';  
          } else {
            obj[d.name] = f[d.name]; // for key(first column)
          }
          // if latest period
          if (showDate) obj[d.name] += ' (' + (getEndDate({ dateEnd: { market: f.dateEnd}, dateParse}, 'market', metrics[0].controls[1].active)) + ')';
        } else {
          obj[d.name] = f[d.name];
        }
      });
      mainArr.push(obj);
    });

    // for first row after header rows
    if (data.total) {
      arrNames.forEach((d, i) => {
        if (i !== 0) {
          firstRowAfterHeader[d.name] = data.total && data.total[d.name] && data.total[d.name].total;
        } else {
          firstRowAfterHeader["key"] = "Total";
        }
      });
    }

    // var ws = XLSX.utils.json_to_sheet(arrJsonToSheet);

    // add headers(first/second rows)
    var ws = XLSX.utils.aoa_to_sheet([arrJsonToSheet, arrMarketFinance]);

    if (data.total) {
      mainArr.unshift(firstRowAfterHeader);
    }
    
    XLSX.utils.sheet_add_json(ws, mainArr, {
      origin: "A3"
    }); // start from third row

    if (!ws["!merges"]) ws["!merges"] = [];

    const calcColName = i => {
      const j = Math.floor(i / 26);
      let a = String.fromCharCode(97 + i % 26).toUpperCase(); // A, B, C, D, ...
      if (j) a = String.fromCharCode(97 + j - 1).toUpperCase() + a; // AA, AB, AC, AD, ...
      return a;
    }

    // merge columns for aggr titles
    const totalNumberOfMergeColumns = numberOfMergeColumns.reduce((acc, cur) => acc += cur, 0);
    for (let i = 0; i < numberOfAggrTitles; i++) {
      const num = i * totalNumberOfMergeColumns + 1;
      if (totalNumberOfMergeColumns > 1) {
        ws["!merges"].push(
          XLSX.utils.decode_range(
            calcColName(num) +
            "1:" +
            calcColName(num + totalNumberOfMergeColumns - 1) +
            "1"
          )
        );
      }

      let tn = 0;
      numberOfMergeColumns.forEach((n, i) => {
        if (n > 1) {
          ws["!merges"].push(
            XLSX.utils.decode_range(
              calcColName(num + tn) +
              "2:" +
              calcColName(num + tn + n - 1) +
              "2"
            )
          );
        }
        tn += n;
      });
    }

    //DELETE full (MAT, ....)name by original name
    for (let i = 0; i < arrNames.length; i++) {
      const a = calcColName(i);

      delete ws[a + "3"].w;
      if (i === 0) {
        ws[a + "3"].v = "";
      } else {
        ws[a + "3"].v = arrNames[i].original_name;
      }
    }
    
    /* generate workbook and add worksheet */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Table", {
      bookType: "xlsx",
      type: "array",
      cellDates: true
    });

    // MEMO
    // range.s.c = 0; // 0 == XLSX.utils.decode_col("A")
    // https://github.com/SheetJS/js-xlsx/issues/214

    /* get worksheet range */
    var range = XLSX.utils.decode_range(wb.Sheets.Table["!ref"]);
    for (var j = range.s.c + 1; j <= range.e.c; ++j) {
      // find name column(A, B, C, ...) by second row j column
      const ref_col_name = XLSX.utils.encode_cell({ r: 2, c: j });
      // find original_name column
      const curr_col_name = wb.Sheets.Table[ref_col_name].v;
      // define format for this column
      const fmt = formatXLSXCell(curr_col_name, arrNames);
      for (var i = range.s.r + 3; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 2 skips the header rows of the worksheet) */
        var ref = XLSX.utils.encode_cell({ r: i, c: j });
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if (!wb.Sheets.Table[ref]) continue;
        /* `.t == "n"` for number cells */
        if (wb.Sheets.Table[ref].t != "n") continue;
        // /* assign the `.z` number format */

        if (!wb.Sheets.Table[ref].v) {
          wb.Sheets.Table[ref].z = '"-"';
        } else {
          if (fmt.name === "pts") {
            wb.Sheets.Table[ref].v = wb.Sheets.Table[ref].v * 100;
            wb.Sheets.Table[ref].z = fmt.fmt;
          } else if (fmt.name === "number") {
            wb.Sheets.Table[ref].z = checkMillionBillion(wb.Sheets.Table[ref].v);
          } else {
            wb.Sheets.Table[ref].z = fmt.fmt;
          }
        }
      }
    }

    var fileName = "table.xlsx";

    //  generate an XLSX file and export
    XLSX.writeFile(wb, fileName);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.query_loading !== this.props.query_loading) return false;
    if (nextProps.isOpenModal !== this.props.isOpenModal) return false;
    if (!_.isEqual(nextProps.aggrData.value ? nextProps.aggrData.value : {}, this.props.aggrData.value ? this.props.aggrData.value : {})) return false
    return true
  }

  render() {
    const {  
      config_id,
      data,
      dims,
      selectedId,
      metrics, 
      sfeControls,
      view,
      isTooltip,
      sortId,
      sortOrder,
      dateEnd,
      dateParse,
      kpis,
      activeTableView,
      datasource,
      full_screen,
      handlerFS
    } = this.props;

    const { 
      activeDropdown, 
      yCoord, 
      selectedItem,
      expandedRowKeys,
      fs_height
    } = this.state;

    // if reference market == "" - disable switching to executive view
    const noExecutive = datasource.titles && datasource.titles["Reference Market"] === "";

    // const existedGroups = kpis.map(d => d.group).filter((v, i, a) => a.indexOf(v) === i);

    console.log("DATA_TABLE IS RENDERED");

    // if (!metrics.length || !data.data.length) return null;

    const cellWidth = 88;//75;

    const aggrs = metrics[3].controls.filter(aggr => aggr.active);
    const calcs = metrics[2].controls.filter(calc => calc.active);
    const calcsFinance = !metrics[9].hidden ? metrics[9].controls.filter(calc => calc.active) : [];
    const calcsForecast = !metrics[10].hidden ? metrics[10].controls.filter(calc => calc.active) : [];
    const calcsSIT = !metrics[12].hidden ? metrics[12].controls.filter(calc => calc.active) : [];

    const showDate = metrics[6].controls[1].active;

    const firstColumn = (
      <Column width={(view === "executive" ? 260 : 170) + (showDate ? 55 : 0)} fixed>
        <HeaderCell className="header-cell-group" align="left">
          <div className="header-cell-group-first-cell">
          </div>
          {['market', 'country'].includes(view) ? (
            <div className="header-cell-group-first-cell-subtitle">
              <span>Total</span>
            </div>
          ) : null}
        </HeaderCell>
        <Cell dataKey="id">
            { rowData => {
              // const showDate = metrics[6].controls[1].active //&& isCountryDim(rowData.dim);
              return <Fragment>
                <ContextMenuTrigger 
                  id="some_unique_identifier"
                  collect={ props => ({ selectedItem: rowData }) }
                  disable={view === "executive" && !["Product Portfolio", "Product Group 1"].includes(rowData.dim)}
                >
                  <SimpleTooltip title={rowData.key + (rowData.default_metric ? ` - ${getMetric(rowData.default_metric)}` : "")} placement="top">
                    <div>
                      { rowData.key }
                      { (rowData.default_metric && rowData.dim === "Product Portfolio") ? <i style={{fontSize: "10px"}}> - {getMetric(rowData.default_metric)}</i> : null }
                    </div>
                  </SimpleTooltip>
                </ContextMenuTrigger>

                {showDate ? <i style={{color: "darkgrey", position: "absolute", top: 0, right: 3}}>{
                  (rowData.dateEnd)
                    ? getEndDate({ dateEnd: { market: rowData.dateEnd}, dateParse}, 'market', metrics[0].controls[1].active) 
                    : getEndDate({ dateEnd, dateParse}, 'market', metrics[0].controls[1].active)
                }</i> : ""}
              </Fragment>
            }}
        </Cell>
      </Column>
    )  

    const otherColumns = activeTableView === 0 ? (
      aggrs.map((aggr, i) => (
        [
          (calcs.length || calcsFinance.length || calcsForecast.length || calcsSIT.length)
            ? (
              <Column key={"col_" + i} width={cellWidth} colSpan={calcs.length + calcsFinance.length + calcsForecast.length + calcsSIT.length} align="center">
                <HeaderCell className="header-cell-group">
                  <div className="header-cell-group-title">{aggr.title}</div>
                  <div className="header-cell-group-subtitle">
                    {calcsFinance.length ? <span style={{width: `${100*calcsFinance.length/(calcs.length + calcsFinance.length + calcsForecast.length + calcsSIT.length)}%`}}>Finance</span> : null}
                    {calcs.length ? <span style={{width: `${100*calcs.length/(calcs.length + calcsFinance.length + calcsForecast.length + calcsSIT.length)}%`}}>Market</span> : null}
                    {calcsForecast.length ? <span style={{width: `${100*calcsForecast.length/(calcs.length + calcsFinance.length + calcsForecast.length + calcsSIT.length)}%`}}>Forecast</span> : null}
                    {calcsSIT.length ? <span style={{width: `${100*calcsSIT.length/(calcs.length + calcsFinance.length + calcsForecast.length + calcsSIT.length)}%`}}>SIT</span> : null}
                  </div>
                  <div className="header-cell-group-subtitle">
                    {calcsFinance.map(calc => {
                      return <span key={'calc_' + calc.name} onClick={e => this.handleClickSort(aggr.name + calc.name)}>
                          {calc.name}
                          <div className="rs-table-cell-header-sort-wrapper">
                            <i className={`rs-table-cell-header-icon-sort ${
                              sortId === aggr.name + calc.name 
                                ? `rs-table-cell-header-icon-sort-${sortOrder}`
                                : ""
                              }`}></i>
                          </div>
                        </span>
                      }
                    )}
                    {calcs.map(calc => {
                      return <span key={'calc_' + calc.name} onClick={e => this.handleClickSort(aggr.name + calc.name)}>
                            {calc.name}
                            <div className="rs-table-cell-header-sort-wrapper">
                              <i className={`rs-table-cell-header-icon-sort ${
                                sortId === aggr.name + calc.name 
                                  ? `rs-table-cell-header-icon-sort-${sortOrder}`
                                  : ""
                                }`}></i>
                            </div>
                          </span>
                      }
                    )}
                    {calcsForecast.map(calc => {
                      return <span key={'calc_' + calc.name} onClick={e => this.handleClickSort(aggr.name + calc.name)}>
                            {calc.title || calc.name}
                            <div className="rs-table-cell-header-sort-wrapper">
                              <i className={`rs-table-cell-header-icon-sort ${
                                sortId === aggr.name + calc.name 
                                  ? `rs-table-cell-header-icon-sort-${sortOrder}`
                                  : ""
                                }`}></i>
                            </div>
                          </span>
                      }
                    )}
                    {calcsSIT.map(calc => {
                      return <span key={'calc_' + calc.name} onClick={e => this.handleClickSort(aggr.name + calc.name)}>
                            {calc.title || calc.name}
                            <div className="rs-table-cell-header-sort-wrapper">
                              <i className={`rs-table-cell-header-icon-sort ${
                                sortId === aggr.name + calc.name 
                                  ? `rs-table-cell-header-icon-sort-${sortOrder}`
                                  : ""
                                }`}></i>
                            </div>
                          </span>
                      }
                    )}
                  </div>
                  {
                    (['market', 'country'].includes(view) && Object.keys(data.total).length) ? (
                      <div className="header-cell-group-subtitle total">
                        {calcsFinance.map((calc, j) => <Fragment key={j}>{selectFormat(calc.format, 0, i)}</Fragment> )}
                        {calcs.map((calc, j) => 
                          <Fragment key={j}>
                            {selectFormat(calc.format, (data.total[aggr.name + calc.name] || {}).total, i)}
                          </Fragment> 
                        )}
                        {calcsForecast.map((calc, j) => 
                          <Fragment key={j}>
                            {selectFormat(calc.format, (data.total[aggr.name + calc.name] || {}).total, i)}
                          </Fragment> 
                        )}
                        {calcsSIT.map((calc, j) => <Fragment key={j}>{selectFormat(calc.format, 0, i)}</Fragment> )}
                      </div>
                    ) : null
                  }
                </HeaderCell>
                <Cell dataKey="id">
                  { rowData => {
                      return calcsFinance.length 
                        ? selectFormat(calcsFinance[0].format, rowData[aggr.name + calcsFinance[0].name])
                        : (calcs.length 
                          ? selectFormat(calcs[0].format, rowData[aggr.name + calcs[0].name]) 
                          : (calcsForecast.length 
                            ? selectFormat(calcsForecast[0].format, rowData[aggr.name + calcsForecast[0].name]) 
                            : (calcsSIT.length 
                              ? selectFormat(calcsSIT[0].format, rowData[aggr.name + calcsSIT[0].name]) 
                              : '')))
                    }
                  }
                </Cell>
              </Column>
            ) : null
        ,
        calcsFinance.length && calcsFinance.map((calc, i) => i > 0
          ? (<Column key={"col_finance_" + i} width={cellWidth} colSpan={calcs.length + calcsFinance.length + calcsForecast.length + calcsSIT.length} align="center">
              <HeaderCell />
              <Cell dataKey="id">
                { rowData => selectFormat(calc.format, rowData[aggr.name + calc.name]) }
              </Cell>
            </Column>)
          : null
          ),

        calcs.length && calcs.map((calc, i) => (!calcsFinance.length && i === 0)
          ? null
          : (<Column key={"col_" + i} width={cellWidth} colSpan={calcs.length + calcsFinance.length + calcsForecast.length + calcsSIT.length} align="center">
              <HeaderCell />
              <Cell dataKey="id">
                { rowData => selectFormat(calc.format, rowData[aggr.name + calc.name]) }
              </Cell>
            </Column>
            )
          ),  
        
        calcsForecast.length && calcsForecast.map((calc, i) => (!calcsFinance.length && !calcs.length && i === 0)
          ? null
          : (<Column key={"col_" + i} width={cellWidth} colSpan={calcs.length + calcsFinance.length + calcsForecast.length + calcsSIT.length} align="center">
              <HeaderCell />
              <Cell dataKey="id">
                { rowData => selectFormat(calc.format, rowData[aggr.name + calc.name]) }
              </Cell>
            </Column>
            )
          ),
          
        calcsSIT.length && calcsSIT.map((calc, i) => (!calcsFinance.length && !calcs.length && !calcsForecast.length && i === 0)
          ? null
          : (<Column key={"col_" + i} width={cellWidth} colSpan={calcs.length + calcsFinance.length + calcsForecast.length + calcsSIT.length} align="center">
              <HeaderCell />
              <Cell dataKey="id">
                { rowData => selectFormat(calc.format, rowData[aggr.name + calc.name]) }
              </Cell>
            </Column>
            )
          )   
        ]
      ))
    ) : (
      datasource.kpiGroups ? (
        datasource.kpiGroups.sort((a, b) => a.order - b.order).map(group => {
          const calcs = kpis.filter(kpi => kpi.group === group.name);

          return [
            calcs.length
              ? (
                <Column key={"col_" + 0} width={cellWidth} colSpan={calcs.length} align="center">
                  <HeaderCell className="header-cell-group">
                    <div className="header-cell-group-title">
                      {group.name}
                    </div>
                    <div className="header-cell-group-subtitle" style={{height: "36px", position: 'relative'}}>
                      {
                        calcs.map(kpi => {
                          return [
                            <span key={kpi.id + 0} id={`header-${kpi.id}`}>
                              <span className="header-cell-group-subtitle-item">{kpi.name}</span>
                              <br/>
                              {kpi.dataType === "sfe" 
                                ? <span style={{fontSize: '8px'}}>{kpi.customer_segment !== "Tgt" ? kpi.customer_segment + " - " : ""}{kpi.customer_specialty !== "Any" ? kpi.customer_specialty + " - " : ""}{kpi.aggr.name}</span>
                                : (kpi.dataType === "target"
                                  ? <span style={{fontSize: '8px'}}></span>
                                  : <span style={{fontSize: '8px'}}>{getMetric(kpi.metric)} - {kpi.aggr.name}</span>)}
                              
                              <div className="rs-table-cell-header-sort-wrapper" onClick={e => this.handleClickSort(kpi.id)}>
                                <i className={`rs-table-cell-header-icon-sort ${
                                  sortId === kpi.id 
                                    ? `rs-table-cell-header-icon-sort-${sortOrder}`
                                    : ""
                                  }`}></i>
                              </div>
                            </span>,
              
                            <div key={kpi.id + 1} className="cog-menu-item">
                              <SettingsIcon onClick={e => this.handleOpenModal(e, `header-${kpi.id}`, kpi)} fontSize="small" />
                            </div>
                          ]
                        })
                      }
                    </div>
                    {
                      (['market', 'country'].includes(view) && Object.keys(data.total).length) ? (
                        <div className="header-cell-group-subtitle total">
                          {calcs.map((kpi, i) => <span key={kpi.id}>{selectFormat(kpi.format, ['finance', 'sfe', 'target'].includes(kpi.dataType) ? 0 : (data.total[kpi.id] || {}).total, i)}</span>)}
                        </div>
                      ) : null
                    }
                  </HeaderCell>
                  
                  <Cell dataKey="id">
                    { rowData => selectFormat(calcs[0].format, rowData[calcs[0].id]) }
                  </Cell>
                </Column>
                ) : null
            ,
            calcs.length && calcs.slice(1).map((kpi, i) => (
              <Column key={"col_" + (i + 1)} width={cellWidth} colSpan={calcs.length} align="center">
                <HeaderCell />
                <Cell dataKey="id">
                  { rowData => selectFormat(kpi.format, rowData[kpi.id]) }
                </Cell>
              </Column>
            ))  
          ]
        })
        
      ) : null
    )
  ;

    return (
      <ChartPaper id={"qwerty_" + config_id} ref={this.qwerty} className={"rs-table-container " + 
        (full_screen ? "full-screen-styles" : "")}
        onClick={(e) => isDoubleClick(e) && handlerFS() }
      >
        {/* show tooltip only if Trend table (activeTableView === 0) and isTooltip === true */}
        {(isTooltip && activeTableView === 0 && data.data.length) ? <Tooltip {...this.props} selectedId={selectedId} /> : null}
        
        <div className="rs-table-main-header">
          <div className="view" onClick={e => this.handlerDropdown(e)}>
            <div className="image-wrapper">
            {view === "market" 
                ?  <MarketIcon />
                  : (view === "country"
                  ? <PublicOutlinedIcon />
                    : (view === "portfolio"
                    ? <PortfolioIcon />
                      : <OfflineBoltOutlinedIcon />))
              }
            </div>
            {view === "market" ? (
              <div className="title">
                <span className="first-letter">M</span>arket view
              </div>
            ) : (view === "country" ? (
                  <div className="title">
                    <span className="first-letter">C</span>ountry view
                  </div>
                ) : (view === "portfolio" ? (
                  <div className="title">
                    <span className="first-letter">P</span>ortfolio view
                  </div>
                ) : (
                  <div className="title">
                    <span className="first-letter">E</span>xecutive view
                  </div>
                )
              )
            )
            }
            <span style={{marginLeft: '0.5rem'}}> - {activeTableView === 0 ? 'Trend table' : 'Customized table'}</span>
            
          </div>
          <div className="cog-menu">
            <Box display="flex" alignItems="center">
              {
                activeTableView === 1 ? (
                  <AddCircleOutlineIcon className="w-icon-small" onClick={e => this.handleOpenModal(e, null, null)} />
                ) : (
                  isTooltip 
                  ? <TableChartOutlinedIcon className="w-icon" onClick={() => this.props.actionFirstHeaderIcon(this.props.isTooltip)} />
                  : <BarChartIcon onClick={() => this.props.actionFirstHeaderIcon(this.props.isTooltip)} />
                )
              }
              {!full_screen ? <FullscreenIcon onClick={handlerFS} /> : <FullscreenExitIcon onClick={handlerFS} />}
              <GetAppIcon className="w-icon" onClick={e => this.downloadXLS(data)} />
            </Box>
          </div>
        </div>  
        
        <Table
          ref={el => this.rsuiteTable = el}
          isTree
          rowKey="id"
          expandedRowKeys={expandedRowKeys}
          virtualized
          minHeight={260}
          height={full_screen ? fs_height : 282}
          headerHeight={!["market", "country"].includes(view) ? 54 : 72}
          rowHeight={17}
          rowClassName={rowData => (rowData && rowData.id === selectedId) ? 'selected' : ""}
          data={data.data}
          onExpandChange={(isOpen, rowData) => {
            const { expandedRowKeys } = this.state;

            const newexpandedRowKeys = isOpen
              ? [...expandedRowKeys, rowData.id]
              : expandedRowKeys.filter(d => d !== rowData.id);

            this.setState({ expandedRowKeys: newexpandedRowKeys });
          }}
          onRowClick={this.onRowClickHandler}
        >
          { firstColumn }
          { otherColumns }
        </Table>

        {/* {activeDropdown ? (
          <div
            className="select-view-menu uk-open uk-dropdown uk-dropdown-bottom-left"
            style={{ top: yCoord }}
            ref={node => this.wrapperRef = node}
          >
            <div className="uk-container cont-close">
              <div className="uk-text-left">Switch to...</div>
              <button
                className="uk-dropdown-close"
                type="button"
                data-uk-close="ratio: .6"
                onClick={() => this.hideDropdown()}
              />
            </div>
            <div className="uk-container cont-link">
              <ul className="uk-nav uk-dropdown-nav">
                {
                  view !== "country" ? (
                    [<li key={0}>
                      <a href="#country_view" className="a-style" onClick={e => this.handlerDropdownLink(e, { selectedItem, view: "country" })}>
                        <div className="left-icons">
                          <img src={icon_country} alt=""/>
                        </div>
                        <div className="links">
                          {"Country view"}
                        </div>
                      </a>
                    </li>,
                    <li key={1} className="uk-nav-divider" />
                    ]
                  ) : null
                }
                {
                  view !== "market" ? (
                    [<li key={2}>
                      <a href="#market_view" className="a-style" onClick={e => this.handlerDropdownLink(e, { selectedItem, view: "market" })}>
                        <div className="left-icons">
                          <img src={icon_market} alt=""/>
                        </div>
                        <div className="links">
                          {"Market view"}
                        </div>
                      </a>
                    </li>,
                    <li key={3} className="uk-nav-divider" />
                    ]
                  ) : null
                }
                {
                  view !== 'portfolio' ? (
                    [<li key={4}>
                      <a href="#portfolio_view" className="a-style" onClick={e => this.handlerDropdownLink(e, { selectedItem, view: "portfolio" })}>
                        <div className="left-icons">
                          <img src={icon_portfolio} alt=""/>
                        </div>
                        <div className="links">Portfolio view</div>
                      </a>
                    </li>,
                    <li key={5} className="uk-nav-divider" />
                    ]
                  ) : null
                }
                {
                  (view !== 'executive' && !noExecutive) ? (
                    <li key={6}>
                      <a href="#executive_view" className="a-style" onClick={e => this.handlerDropdownLink(e, { selectedItem, view: "executive" })}>
                        <div className="left-icons">
                          <img src={icon_executive} alt=""/>
                        </div>
                        <div className="links">Executive view</div>
                      </a>
                    </li>
                  ) : null
                }
              </ul>
            </div>
          </div>
        ) : null} */}

        <ContextMenu id="some_unique_identifier">
          <ContextMenuPaper>
            <div className="contextmenu-table-header">
              <span>{selectedItem ? selectedItem.key : view.toUpperCase() + " VIEW"}</span>
              {/*<CloseIcon fontSize="small" onClick={() => this.hideDropdown()} />*/}
            </div>
            <div className="contextmenu-table-content">        
                {!["executive", "country"].includes(view) ? <MenuItem data={{ view: "country" }} onClick={this.handlerDropdownLink}>
                    <Box mr={.5}>
                      <PublicOutlinedIcon />
                    </Box>
                    <span>
                      {"Country View"}
                    </span>
                </MenuItem> : null }
                
                {!["market"].includes(view) ? <MenuItem data={{ view: "market" }} onClick={this.handlerDropdownLink}>
                    <Box mr={.5}>
                      <MarketIcon />
                    </Box>
                    <span>
                      {"Market View"}
                    </span>
                </MenuItem> : null }

                {!["executive", "portfolio"].includes(view) ? <MenuItem data={{ view: "portfolio" }} onClick={this.handlerDropdownLink}>
                    <Box mr={.5}>
                      <PortfolioIcon />
                    </Box>
                    <span>Portfolio view</span>
                </MenuItem> : null }
                {
                  ["executive"].includes(view) || noExecutive ? null : (
                    <MenuItem data={{ view: "executive" }} onClick={this.handlerDropdownLink}>
                      <Box mr={.5}>
                        <OfflineBoltOutlinedIcon />
                      </Box>
                      <span>Executive view</span>
                    </MenuItem>
                  )
                }
            </div>
          </ContextMenuPaper>
        </ContextMenu>
      </ChartPaper>
    );
  }
}

const mapStateToProps = (state, props) => {
  const metrics = (props.controlsData && props.controlsData[3].data) || [];
  const sfeControls = (props.controlsData && props.controlsData[4].data) || [];

  return {
    config_id: props.config_id,
    data: props.tableData || { total: {} },
    selectedId: props.selectedId,
    metrics,
    sfeControls,
    isTooltip: props.isTooltip,
    view: props.view,
    dims: props.dims,
    actives: props.actives || {},
    sortId: props.sortId,
    sortOrder: props.sortOrder || 'desc',
    dateEnd: props.dateEnd,
    dateParse: props.dateParse,
    activeTableView: props.activeTableView,
    kpis: props.defaultKPIs[props.activeTableView].kpis,
    datasource: props.datasource
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { config_id } = props;
  return {
    actionSelect: data => dispatch(actionSelect(config_id, data)),
    actionSelectView: (data, key, dim) => dispatch(actionSelectView(config_id, data, key, dim)),
    actionChangeSort: (sortId, sortOrder) => dispatch(actionChangeSort(config_id, sortId, sortOrder)),
    actionChangeKPI: (data, type) => dispatch(actionChangeKPI(config_id, data, type)),
    actionShowModal: (modalType, config) => dispatch(actionShowModal(modalType, config)),
    actionHideModal: () => dispatch(actionHideModal())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RsuiteTable);