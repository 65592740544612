import * as d3 from 'd3';
import cloneDeep from 'lodash/cloneDeep';

import {
  initData,
  getTableData,
  getChartData,
  initControlsData,
  calculateLastDate,
  // calculateLastDateFinance,
  calculateActives,
  // getDates,
  updateHighlights,
  getTableSortId,
  updateDimensions,
  getKPIs,
  getCustomKPIs,
  getCharts,
  getChartControls,
  findSelectedId,
  getMetric,
} from './helper';

export const initState = (init_state, project_config) => {
  const { controlsData, defaultConfig, charts } = project_config;

  const config = Object.assign({}, init_state.config || defaultConfig);

  // temporary <<<
  config.controlsData.Metrics.Frequency = config.controlsData.Metrics.Frequency.map(
    (f) => (f === 'Quarterly' || f === '1' ? '1' : '0')
  );
  // >>>

  const atuDates = init_state.datasource.subsetMap['atu']
    ? init_state.datasource.subsetMap['atu'].dates
    : [];

  // initialize date for ATU charts
  charts.forEach((c) => {
    if (c.dataType === 'atu')
      c.date = atuDates.length ? atuDates[atuDates.length - 1] : null;
  });

  const state = {
    user: config.user,
    aggrData: {},
    datasource: init_state.datasource,
    channels: config.channels,
    color_codes: config.color_codes,
    daily_data: config.daily_data,
    target_data: config.target_data,
    market_access: config.market_access,
    tab_footnote: config.tab_footnote,
    view: config.view,
    selectedTabId: config.selectedTabId,
    selectedId: config.selectedId,
    dateInterval: d3.timeMonth,
    dateFormat: d3.utcFormat('%Y%m'),
    dateParse: d3.utcParse('%Y%m'),
    dims: config.dims,
    isTooltip: config.isTooltip,
    isShowRP: !window.matchMedia('(max-width : 1024px)').matches, //check if iPad or less screen //config.isShowRP,
    activeTableView: config.activeTableView || 0,
    activeChartView: config.activeChartView,
    charts,
    chartControls: {
      finance: {
        'act-1': { name: 'Act-2018', value: true },
        act: { name: 'Act', value: true },
        bud: { name: 'Bud', value: true },
        f1: { name: 'F1', value: true },
        f2: { name: 'F2', value: true },
      },
      promo: {
        selectedCategoryGroup: 'All',
        categoryGroups: ['All'],
      },
      forecast: {
        act: { name: 'Act', value: true },
        bud: { name: 'Bud', value: true },
        f1: { name: 'F1', value: true },
        f2: { name: 'F2', value: true },
      },
      patient: {
        displayType: 'percents', // ["percents", "absolute"]
        dynamicCleaned: 'new+switch', // ["new", "switch", "new+switch"]
      },
      patient_weekly: {
        displayType: 'percents', // ["percents", "absolute"]
        dynamicCleaned: 'new+switch', // ["new", "switch", "new+switch"]
      },
      MSE_Comp: {
        selectedItem1: null,
        selectedItem2: null,
      },
      sob: {
        displayType: 'percents', // ["percents", "absolute"]
        selectedPType: 'TOTAL PATIENTS',
        ptype: ['TOTAL PATIENTS'],
        selectedAge: 'All ages',
        age: ['All ages'],
        selectedDynamic: 'ON DRUG',
        dynamic: ['ON DRUG'],
      },
      crm: {
        chartsBy: ['Segment', 'Channel'],
        selectedChartsBy: 'Segment', // ["Segment", "Channel"]
        displayType: 'HCP', // ["HCP", "HO"]
        selectedChannel: ['TOTAL'],
        channels: [],
        selectedSegment: null,
        segments: [],
      },
    },
  };

  const _charts = state.datasource.charts;
  const _excludeCharts = state.datasource.excludeCharts;
  const subsets = Object.keys(state.datasource.subsetMap);
  if (_charts) {
    state.charts = state.charts
      .filter((c) => _charts.includes(c.id))
      .filter((c) => subsets.includes(c.dataType));
  } else if (_excludeCharts) {
    state.charts = state.charts
      .filter((c) => !_excludeCharts.includes(c.id))
      .filter((c) => subsets.includes(c.dataType));
  } else {
    // filter hidden charts by default
    state.charts = state.charts.filter(
      (c) => !c.defaultHidden && subsets.includes(c.dataType)
    );
  }
  state.charts.forEach((c, i) => (c.index = i));

  const controlsDataCopy = cloneDeep(controlsData);

  // Metric Demand
  if (state.datasource.excludeColumns) {
    controlsDataCopy[3].data[1].controls = state.datasource.subsetMap[
      'market'
    ].columns
      .filter((d) => !state.datasource.excludeColumns.includes(d))
      .map((d) => ({ name: d, value: d, title: getMetric(d) }));
  } else {
    controlsDataCopy[3].data[1].controls = state.datasource.subsetMap[
      'market'
    ].columns.map((d) => ({
      name: d,
      value: d,
      title: getMetric(d),
    }));
  }

  // add Default metric
  controlsDataCopy[3].data[1].controls.push({
    name: 'Default',
    value: 'Default',
    title: 'Default',
    hidden: state.view !== 'executive',
  });

  // add EOG kpis if needed (available PE12PUBPCK metric)
  if (state.datasource.subsetMap['market'].columns.includes('PE12PUBPCK')) {
    controlsDataCopy[3].data[2].controls.forEach((d) => {
      if (d.type === 'eog') d.hidden = false;
    });
  }

  // configure correct time periods in dependence of datasetType
  if (state.datasource.datasetType === 'quarterly') {
    // board time aggr
    controlsDataCopy[3].data[3].controls = [
      { name: 'MTH', title: 'Month Period', period: 0 },
      { name: 'RQTR', title: 'Rolling Quarter', period: 1 },
      { name: 'R6M', title: 'Rolling 6 months', period: 2 },
      { name: 'YTD', title: 'Year to Date', period: 9 },
      { name: 'MAT', title: 'Moving Annual Total', period: 4 },
    ];
    // chart time aggr
    controlsDataCopy[3].data[4].controls = [
      { name: 'MTH', title: 'Month Period', period: 0 },
      { name: 'RQTR', title: 'Rolling Quarter', period: 1 },
      { name: 'R6M', title: 'Rolling 6 months', period: 2 },
      { name: 'YTD', title: 'Year to Date', period: 9 },
      { name: 'MAT', title: 'Moving Annual Total', period: 4 },
    ];
    // charts backdata
    controlsDataCopy[3].data[5].controls = [
      { name: '1 yr', period: 4 },
      { name: '2 yr', period: 8 },
      { name: '3 yr', period: 12 },
    ];
  }

  // titles
  const titles = state.datasource.titles;
  controlsDataCopy.forEach((c, i) => {
    if (titles && titles.tabs && titles.tabs[c.name]) {
      c.title = titles.tabs[c.name];
    }
    c.data.forEach((cd) => {
      cd.title = cd.name;
      if (titles && typeof titles[cd.name] !== 'undefined') {
        if (titles[cd.name] === '') {
          cd.hidden = true;
        } else {
          cd.title = titles[cd.name];
        }
      }
    });
  });

  // custom date
  const dates = state.datasource.subsetMap['market'].dates;
  controlsDataCopy[3].data[6].customDate.date = state.dateParse(
    dates[dates.length - 1]
  );
  controlsDataCopy[3].data[6].customDate.active = false;

  // update dimensions
  updateDimensions(controlsDataCopy);

  state.controlsData = initControlsData(
    state,
    controlsDataCopy,
    config.controlsData
  );

  // check if executive view is default
  if (state.view === 'executive') {
    // select only Category0 dimension
    controlsDataCopy[1].data.forEach((d) =>
      ![0, 7].includes(d.index) ? (d.disabled = true) : null
    );
    controlsDataCopy[2].data.forEach((d) => (d.disabled = true));
    controlsDataCopy[3].data[7].controls = [
      { name: 'Country', index: 0, active: true, disabled: true },
      { name: 'Category5', index: 1, active: true },
      {
        name: 'Product Portfolio',
        index: 2,
        title: 'Reference Market',
        active: true,
        disabled: true,
      },
      { name: 'Product Group 1', index: 3, active: false },
    ];

    state.dims = controlsDataCopy[3].data[7].controls
      .filter((d) => d.active)
      .map((d) => d.name);
    controlsDataCopy[3].data[1].controls.forEach(
      (d, i) => (d.active = d.name === 'Default')
    );
  }

  // init data
  state.data = initData(state.controlsData);

  // update control highlights
  updateHighlights(state);

  // recalculate last date
  state.dateEnd = calculateLastDate(state);

  // calculate actives values for header and footer
  state.actives = calculateActives(state, state.controlsData);

  // add default KPIs
  state.defaultKPIs = [
    {
      id: 0,
      kpis: getKPIs(state, state.controlsData),
    },
    {
      id: 1,
      kpis: getCustomKPIs(state, state.controlsData),
    },
  ];

  state.charts = getCharts(state);

  state.sortId = config.sortId || getTableSortId(state.controlsData[3].data);
  state.sortOrder = config.sortOrder || 'desc';

  return state;
};

export const postQueryUpdate = (state) => {
  state.tableData = getTableData(state);
  state.selectedId = findSelectedId(state); //state.tableData.data[0].id;

  state.chartControls = getChartControls(state);
  state.chartData = getChartData(state);

  return state;
};
