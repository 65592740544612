import {
  all,
  takeEvery,
  takeLatest,
  call,
  put,
  select,
  delay,
} from 'redux-saga/effects';

import * as actions from './../actions';
import { actionHideModal } from '../../components/Modals/actions';
import * as api from './../../services/api';
import { serializeConfig } from './../reducers/config/helper';

const queries = {
  AGGREGATE: {
    fields: [
      'view',
      'dims',
      'controlsData',
      {
        name: 'datasource',
        fields: ['datasource_id', 'slug', 'default_metrics', 'datasetType'],
      },
      'data',
      'defaultKPIs',
      'activeTableView',
      'charts',
      'actives',
    ],
  },
};

const getState = (config_id) =>
  select((state) => state.data.configs[config_id]);

function* watchLoadDataSaga() {
  yield takeLatest(actions.LOAD_DATA, function* ({ payload }) {
    console.log('SAGA: load data');
    yield put(actions.actionInitLoading(true));

    // get project config
    const project_config = yield call(api.getProjectConfig);

    // get user configs
    const configs = yield call(api.getConfig);

    if (!project_config.error && !configs.error) {
      console.log('!!! project_config, configs: ', project_config, configs);
      yield put(actions.actionDidLoadData({ project_config, configs }));

      // select the first config
      if (configs.length > 0) {
        yield put(actions.actionSelectTab(0));
        yield put(actions.actionSelectConfig(configs[0].config_id));
      }
    } else {
      console.log('???', configs);
    }
    yield put(actions.actionInitLoading(false));
  });
}

function* watchSelectConfigSaga() {
  yield takeEvery(actions.SELECT_CONFIG, function* ({ config_id }) {
    console.log('SAGA: select config', config_id);
    yield put(actions.actionConfigLoading(config_id, true));

    const state = yield getState(config_id);

    if (!state.datasource) {
      yield delay(150);

      // get datasource configs from server
      const datasource = yield call(
        api.getDatasourceById,
        state.config.datasource_id
      );

      if (!datasource.error) {
        console.log('!!! datasource load', datasource);
        if (datasource.status === 'ready') {
          yield put(actions.actionDidSelectConfig(config_id, datasource));

          // initialize config data
          yield put(actions.actionInitConfig(config_id));
          yield call(initConfigSaga, { config_id });
        } else {
          console.log(`Datasource status is ${datasource.status}`);
        }
      } else {
        console.log('???', datasource);
      }
    } else {
      yield delay(500);
    }

    yield put(actions.actionConfigLoading(config_id, false));
  });
}

// Initialize config
function* initConfigSaga({ config_id }) {
  console.log('SAGA: init config');

  yield call(querySaga, config_id, 'AGGREGATE');

  yield put(actions.actionDidInitConfig(config_id));
}
// function* watchInitConfigSaga() {
//   yield takeEvery(actions.INIT_CONFIG, initConfigSaga);
// }

// Open config
function* watchOpenSavedConfigSaga() {
  yield takeEvery(actions.OPEN_SAVED_CONFIG, function* ({ payload }) {
    const { config_id } = payload;
    console.log('SAGA: open config');
    const state = yield getState(config_id);

    if (state === undefined) {
      const result = yield call(api.openSavedConfig, config_id);
      if (!result.error) {
        yield call(configSaga, { config_id, result });
      } else {
        if (result.error === 'Unauthorized') {
          yield put(actionHideModal());
        }
      }
    }
  });
}

// Add new worksheet
function* watchAddNewWorksheetSaga() {
  yield takeEvery(actions.ADD_NEW_WORKSHEET, function* ({ payload }) {
    const { data } = payload;
    console.log('SAGA: add new worksheet');

    const result = yield call(api.postAddNewWorksheet, { data });
    if (!result.error) {
      yield call(configSaga, { config_id: result.config_id, result });
    } else {
      if (result.error === 'Unauthorized') {
        yield put(actionHideModal());
      }
    }
  });
}

// Close/delete config
function* watchCloseConfigSaga() {
  yield takeEvery(actions.CLOSE_CONFIG, function* ({ payload }) {
    const { action, config_id } = payload;
    console.log('SAGA: close/delete config');

    const result = yield call(
      api[action === 'delete' ? 'deleteSavedConfig' : 'closeConfig'],
      config_id
    );
    if (!result.error) {
      const configs = yield select((state) => state.data.configs);
      const keys = Object.keys(configs);
      const idx_by_id = keys.findIndex((id) => +id === +config_id);
      const activeTab = yield select((state) => state.data.selectedTab);

      if (activeTab === +idx_by_id && keys.length === 1) {
        // Close last config
        yield put(actions.actionSelectTab(null));
        if (action === 'delete') yield put(actionHideModal());
        yield put(actions.actionDidCloseConfig({ config_id }));
      } else if (activeTab > +idx_by_id) {
        // Close before selectedTab
        yield put(
          actions.actionDidCloseConfig({
            config_id,
            selectedTabIndex: activeTab - 1,
          })
        );
        if (action === 'delete') yield put(actionHideModal());
      } else if (activeTab < +idx_by_id) {
        // Close after selectedTab
        if (action === 'delete') yield put(actionHideModal());
        yield put(actions.actionDidCloseConfig({ config_id }));
      } else if (activeTab === 0 && keys.length > 1) {
        // Close selectedTab
        yield put(actions.actionSelectTab(0));
        yield put(actions.actionSelectConfig(+keys[1]));
        yield put(actions.actionDidCloseConfig({ config_id }));
        if (action === 'delete') yield put(actionHideModal());
      } else if (activeTab === +idx_by_id) {
        // Close selectedTab
        yield put(actions.actionSelectConfig(+keys[0]));
        yield put(actions.actionSelectTab(0));
        if (action === 'delete') yield put(actionHideModal());
        yield put(actions.actionDidCloseConfig({ config_id }));
      }
    } else {
      if (result.error === 'Unauthorized') {
        yield put(actionHideModal());
      }
    }
  });
}

// Update config
function* watchUpdateConfigSaga() {
  yield takeEvery(actions.UPDATE_CONFIG, function* ({ payload }) {
    const { config_id, data } = payload;
    console.log('SAGA: update config');

    const result = yield call(api.updateConfig, config_id, data);
    if (!result.error) {
      yield put(actions.actionDidUpdateConfig({ config_id, data }));
      yield put(actionHideModal());
      yield put(
        actions.actionAddNotification({
          type: 'success',
          text: `Analysis ${payload.data.name} was updated successfully!`,
          //id,
        })
      );
    } else {
      if (result.error === 'Unauthorized') {
        yield put(actionHideModal());
      }
      yield put(
        actions.actionAddNotification({
          type: 'error',
          text: `Error updating Analysis: ${result.error}`,
          //id,
        })
      );
    }
  });
}

function* configSaga({ config_id, result }) {
  yield put(actions.actionDidOpenSavedConfig(result));
  yield put(actions.actionSelectConfig(config_id));
  const configs = yield select((state) => state.data.configs);
  const idx = Object.keys(configs).findIndex((id) => +id === config_id);
  if (idx !== -1) yield put(actions.actionSelectTab(idx));
  yield put(actionHideModal());
}

// Save config
function* watchSaveConfigSaga() {
  yield takeEvery(actions.SAVE_CONFIG, function* ({ config_id, payload }) {
    yield put(actions.actionQueryLoading(true));
    console.log('SAGA: save config');
    const data = payload || serializeConfig(yield getState(config_id));

    const result = yield call(api.updateConfig, config_id, data);
    if (!result.error) {
      yield put(actions.actionDidSaveConfig(config_id, data));
      yield put(
        actions.actionAddNotification({
          type: 'success',
          text: `Analysis was saved successfully!`,
          //id,
        })
      );
    } else {
      yield put(
        actions.actionAddNotification({
          type: 'error',
          text: `Error saving Analysis: ${result.error}`,
          //id,
        })
      );
    }
    yield put(actions.actionQueryLoading(false));
  });
}

function* querySaga(config_id, payload) {
  console.log('SAGA: query data', config_id, payload);
  // yield put(actions.actionQueryLoading(true));

  const state = yield getState(config_id);
  const obj = {};
  if (payload && queries[payload]) {
    queries[payload].fields.forEach((field) => {
      if (typeof field === 'string') {
        obj[field] = state[field];
      } else {
        obj[field.name] = {};
        field.fields.forEach(
          (f) => (obj[field.name][f] = state[field.name][f])
        );
      }
    });
  }

  const data = yield call(api.postQuery, obj);

  if (!data.error) {
    console.log('!!! query ', data);
    yield put(actions.actionDidQuery(config_id, data));
    return true;
  } else {
    console.log('??? query ', data.error);
    return false;
  }
  // yield put(actions.actionQueryLoading(false));
}

function* watchQuerySaga() {
  yield takeEvery(actions.QUERY, function* ({ config_id, payload }) {
    console.log('SAGA: query data', config_id, payload);
    yield put(actions.actionQueryLoading(true));

    const state = yield getState(config_id);
    const obj = {};
    if (payload && queries[payload]) {
      queries[payload].fields.forEach((field) => (obj[field] = state[field]));
    }

    const data = yield call(api.postQuery, obj);

    if (!data.error) {
      console.log('!!! query ', data);
      yield put(actions.actionDidQuery(config_id, data));
    } else {
      console.log('??? query ', data.error);
    }
    yield put(actions.actionQueryLoading(false));
  });
}

// Controls
function* watchControlsSaga() {
  yield takeEvery(actions.CONTROLS, function* ({ config_id, payload }) {
    console.log('SAGA: controls');
    yield put(actions.actionQueryLoading(true));
    // yield put(actions.actionQuery(config_id, 'AGGREGATE'));
    yield call(querySaga, config_id, 'AGGREGATE');
    yield put(actions.actionDidControls(config_id, payload));
    yield put(actions.actionQueryLoading(false));
  });
}

// Controls Reset
function* watchControlsResetSaga() {
  yield takeEvery(actions.CONTROL_RESET, function* ({ config_id, payload }) {
    console.log('SAGA: controls reset');
    yield put(actions.actionQueryLoading(true));
    // yield put(actions.actionQuery(config_id, 'AGGREGATE'));
    yield call(querySaga, config_id, 'AGGREGATE');
    yield put(actions.actionDidControlReset(config_id, payload));
    yield put(actions.actionQueryLoading(false));
  });
}

// Select View
function* watchSelectViewSaga() {
  yield takeEvery(actions.SELECT_VIEW, function* ({ config_id, payload }) {
    console.log('SAGA: select view');
    yield put(actions.actionQueryLoading(true));

    if (yield call(querySaga, config_id, 'AGGREGATE')) {
      yield put(actions.actionDidSelectView(config_id, payload));
    }

    yield put(actions.actionQueryLoading(false));
  });
}

// Select View
function* watchSelectTableViewSaga() {
  yield takeEvery(
    actions.SELECT_TABLE_VIEW,
    function* ({ config_id, payload }) {
      console.log('SAGA: select table view');
      yield put(actions.actionQueryLoading(true));
      yield call(querySaga, config_id, 'AGGREGATE');
      yield put(actions.actionDidSelectTableView(config_id, payload));
      yield put(actions.actionQueryLoading(false));
    }
  );
}

// Change KPIs
function* watchChangeKPISaga() {
  yield takeEvery(actions.CHANGE_KPI, function* ({ config_id, payload }) {
    console.log('SAGA: change kpi');
    yield put(actions.actionQueryLoading(true));
    yield call(querySaga, config_id, 'AGGREGATE');
    yield put(actions.actionDidChangeKPI(config_id, payload));
    yield put(actions.actionQueryLoading(false));
  });
}

// Get datasources
function* watchGetDatasourcesSaga() {
  yield takeEvery(actions.GET_DATASOURCES, function* () {
    console.log('SAGA: get datasources');
    const result = yield call(api.getDatasource);
    yield put(actions.actionDidGetDatasources(result));
  });
}

export default function* watchWorkspaceSaga() {
  yield all([
    watchLoadDataSaga(),
    watchSelectConfigSaga(),
    // watchQuerySaga(),
    // watchInitConfigSaga(),
    watchOpenSavedConfigSaga(),
    watchAddNewWorksheetSaga(),
    watchCloseConfigSaga(),
    watchUpdateConfigSaga(),
    watchSaveConfigSaga(),
    watchControlsSaga(),
    watchControlsResetSaga(),
    watchSelectViewSaga(),
    watchSelectTableViewSaga(),
    watchChangeKPISaga(),
    watchGetDatasourcesSaga(),
  ]);
}
