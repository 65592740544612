import React, { createRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { actionHideModal } from '../actions';
import {
  actionOpenSavedConfig,
  actionAddNewWorksheet,
} from '../../../store/actions';
import { getSavedConfigList } from '../../../services/api';
import  history from '../../../services/history';

import FormikSample from '../../Other/FormikSample';
import ButtonSample from '../../Other/ButtonSample';
import SimpleMaterialTable from '../../Other/SimpleMaterialTable';

const useStyles = makeStyles((theme) => {
  return {
    blockLeft: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingRight: '1rem',
      '&::before': {
        display: 'block',
        position: 'absolute',
        content: "' '",
        width: '1px',
        height: '100%',
        top: '0px',
        right: '0px',
        borderRight: `1px solid ${theme.palette.grey[300]}`,
      },
    },
    blockRight: {
      paddingLeft: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& h6': {
        marginBottom: '.5rem',
      },
    },
    footerRight: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    footerLeft: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    openButtonLabel: {
      fontSize: '12px',
      padding: '.5rem 1rem',
    },
  };
});

export const DatasourcesTable = ({ setSelectedRow, selectedRow, actionHideModal }) => {
  const [savedConfigs, setSavedConfigs] = useState(null);
  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Last change',
      field: 'updated_at',
      props: {
        style: {
          width: '110px',
          fontStyle: 'italic',
        },
      },
    },
  ];

  useEffect(() => {
    getSavedConfigList({
      success: (res) => {
        setSavedConfigs(
          res.map((el) => ({
            ...el,
            updated_at: new Date(el.updated_at).toLocaleString(),
          }))
        );
      },
      error: (e) => {
        history.push("/")
        actionHideModal();
        console.log('ERROR GET Saved Config List');
      },
    });
  }, []);

  return (
    <SimpleMaterialTable
      columns={columns}
      data={savedConfigs ? savedConfigs.filter((d) => !d.in_use) : []}
      loader={!Boolean(savedConfigs)}
      tableContainerProps={{
        style: {
          width: '400px',
        },
      }}
      rowsProps={(row, ind) => ({
        onClick: (e) =>
          selectedRow && `cell-${selectedRow.config_id}` === e.target.id
            ? setSelectedRow(undefined)
            : setSelectedRow(
                savedConfigs.find((d) => `cell-${d.config_id}` === e.target.id)
              ),
        style:
          selectedRow && row.config_id === selectedRow.config_id
            ? {
                backgroundColor: '#e0e0e0',
              }
            : {},
      })}
    />
  );
};

function AddConfigModal(props) {
  const {
    datasources,
    actionHideModal,
    actionOpenSavedConfig,
    actionAddNewWorksheet,
  } = props;
  const classes = useStyles();
  const formikRef = createRef();

  const [selectedRow, setSelectedRow] = React.useState(null);

  return (
    <>
      <div className={classes.blockLeft}>
        <Typography variant="h6" component="h6">
          Saved Analyzes
        </Typography>
        <div className="mb-3">
          <DatasourcesTable
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            actionHideModal={actionHideModal}
          />
        </div>
        <div className={classes.footerLeft}>
          <ButtonSample
            name="Open"
            method={() =>
              selectedRow
                ? actionOpenSavedConfig({ config_id: selectedRow.config_id })
                : ''
            }
          />
          <Typography component="span" className={classes.openButtonLabel}>
            {selectedRow ? selectedRow.name : ''}
          </Typography>
        </div>
      </div>
      <div className={classes.blockRight} style={{ paddingLeft: '1rem' }}>
        <div>
          <Typography variant="h6" component="h6">
            New Analysis
          </Typography>
          <FormikSample
            ref={formikRef}
            index={1}
            data={null}
            datasources={datasources}
            submit={(data) => {
              if (!Object.keys(data).find((key) => !data[key])) {
                actionAddNewWorksheet({ data });
              }
            }}
          />
        </div>
        <div className={classes.footerRight}>
          <ButtonSample
            name="Cancel"
            color="default"
            method={actionHideModal}
          />
          <ButtonSample
            name="Add"
            method={() => formikRef.current.submitForm()}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (rootState, props) => {
  const state = rootState.modal;
  const dataState = rootState.data;
  return {
    ...state,
    datasources: dataState.datasources || [],
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionAddNewWorksheet: (data) => dispatch(actionAddNewWorksheet(data)),
    actionOpenSavedConfig: (data) => dispatch(actionOpenSavedConfig(data)),   
    actionHideModal: () => dispatch(actionHideModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddConfigModal);
