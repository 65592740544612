import { useEffect, useState, useRef } from 'react';

// Hook for getting async state
function useAsyncState(initialValue) {
  const [state, setState] = useState(initialValue);
  const stateRef = useRef(state);
  const setter = (x) =>
    new Promise((resolve) => {
      setState(x);
      resolve(x);
    });
  stateRef.current = state;
  return [state, setter, stateRef];
}

 const useRectBounding = () => {
  const ref = useRef();
  const [rect, setRect] = useState({});

  const set = () =>
    setRect(ref && ref.current ? ref.current.getBoundingClientRect() : {});

  useEffect(() => {
    set();
    window.addEventListener('resize', set);
    return () => window.removeEventListener('resize', set);
  }, []);

  return [rect, ref];
};

export { useAsyncState, useRectBounding };
