import update from 'immutability-helper';
import * as actions from '../actions';
import configReducer from './config';

const init_state = {
  version: 'v1.2.4',
  init_loading: false,
  notifications: [],
  query_loading: 0,
  selectedTab: 0,
  fs_index: null,
  fs_table: false,
  datasources: [],
};

export default (state = init_state, action) => {
  const { type, payload, config_id } = action;

  if (config_id) {
    return {
      ...state,
      configs: {
        ...state.configs,
        [config_id]: configReducer(state, action),
      },
    };
  }

  switch (type) {
    // INIT
    case actions.INIT_LOADING: {
      return {
        ...state,
        init_loading: payload,
      };
    }

    // initialize configs
    case actions.DID_LOAD_DATA: {
      const configs = {};
      payload.configs.forEach((config) => {
        const { config_id } = config;
        configs[config_id] = {};

        configs[config_id].config = config.config;
        configs[config_id].loading = false;
      });

      return {
        ...state,
        project_config: payload.project_config,
        configs,
      };
    }

    case actions.DID_CLOSE_CONFIG: {
      const newConfigs = state.configs;
      delete newConfigs[payload.config_id];
      return {
        ...state,
        selectedTab:
          payload.selectedTabIndex !== null
            ? payload.selectedTabIndex
            : state.selectedTab,
        configs: newConfigs,
      };
    }

    case actions.DID_OPEN_SAVED_CONFIG: {
      return {
        ...state,
        configs: {
          ...state.configs,
          [payload.config_id]: {
            loading: false,
            config: payload.config,
          },
        },
      };
    }

    case actions.DID_UPDATE_CONFIG: {
      return {
        ...state,
        configs: update(state.configs, {
          [payload.config_id]: {
            config: {
              $set: {
                ...state.configs[payload.config_id].config,
                ...payload.newData,
              },
            },
          },
        }),
      };
    }

    case actions.SELECT_TAB: {
      return {
        ...state,
        selectedTab: payload.index,
      };
    }

    // DATASOURCES

    case actions.DID_GET_DATASOURCES: {
      return {
        ...state,
        datasources: payload.data,
      };
    }

    // QUERY Loading
    case actions.QUERY_LOADING: {
      return {
        ...state,
        query_loading: payload
          ? state.query_loading + 1
          : state.query_loading - 1,
      };
    }

    case actions.SET_FS_INDEX: {
      return {
        ...state,
        fs_index: payload,
      };
    }

    case actions.SET_FS_TABLE: {
      return {
        ...state,
        fs_table: payload,
      };
    }

    case actions.ADD_NOTIFICATION: {
      const id = `${payload.type}_${Math.random()}`;

      const newN = {
        ...payload,
        id,
      };

      return {
        ...state,
        notifications: [...state.notifications, newN],
      };
    }

    case actions.REMOVE_NOTIFICATION: {
      const newNotifications = state.notifications.filter((el) => el.id !== payload.id);

      return {
        ...state,
        notifications: newNotifications,
      };
    }

    default:
      return state;
  }
};
